import { Dispatch } from 'redux';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { AddNotification } from 'src/components/Notifications/Provider/types';
import { addVacancies, changeListLoadingStatus } from 'src/models/employerVacancies/managerVacancies';
import { VacanciesList } from 'src/models/employerVacancies/vacancy.types';
import fetcher from 'src/utils/fetcher';

const GET_VACANCIES_BY_GROUP_ID = '/shards/employer/get_vacancies_by_group_id';

declare global {
    interface FetcherGetApi {
        [GET_VACANCIES_BY_GROUP_ID]: {
            queryParams: {
                offset?: number;
                groupId?: string;
            };
            response: { vacanciesData: VacanciesList };
        };
    }
}

type FetchVacanciesByGroupId = (
    args: { params: { groupId?: string; offset?: number; replaceExistedVacancies?: boolean }; signal?: AbortSignal },
    addNotification: AddNotification
) => (dispatch: Dispatch) => Promise<void>;

const fetchVacanciesByGroupId: FetchVacanciesByGroupId =
    ({ params, signal }, addNotification) =>
    async (dispatch) => {
        dispatch(changeListLoadingStatus({ isFetching: true }));

        try {
            const response = await fetcher.get(GET_VACANCIES_BY_GROUP_ID, {
                params,
                signal,
            });

            dispatch(
                addVacancies({
                    vacancies: response.vacanciesData,
                    groupId: params.groupId,
                    replaceExistedVacancies: params.replaceExistedVacancies,
                })
            );
        } catch (err) {
            dispatch(changeListLoadingStatus({ isFetching: false }));
            defaultRequestErrorHandler(err, addNotification);

            throw err;
        }
    };

export default fetchVacanciesByGroupId;
