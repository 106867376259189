import { useIsZarplataPlatform } from 'src/hooks/usePlatform';

interface UseShowRisePage {
    isRisePageEnabled: boolean;
}

const useShowRisePage = (): UseShowRisePage => {
    const isZP = useIsZarplataPlatform();

    return {
        isRisePageEnabled: !isZP,
    };
};

export default useShowRisePage;
