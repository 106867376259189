import { FormEventHandler, useMemo } from 'react';
import { Field } from 'react-final-form';

import {
    createStaticDataProvider,
    findStaticDataFetcherItemByValue,
    StaticDataFetcherItem,
} from '@hh.ru/magritte-common-data-provider';
import { Input, Select, Text, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { getFunnelErrorMessage } from 'src/components/Employer/Funnel/utils';
import { funnelNameValidator } from 'src/components/Employer/Funnel/validators';
import Form from 'src/components/Form';
import translation from 'src/components/translation';
import { Vacancy } from 'src/models/employerVacancies/vacancy.types';

export const FORM_NAME = 'create-funnel';

const TrlKeys = {
    inputs: {
        funnelName: {
            placeholder: 'vacancy.createFunnel.modal.inputs.funnelName.placeholder',
        },
        vacancyTemplate: {
            placeholder: 'vacancy.createFunnel.modal.inputs.vacancyTemplate.placeholder',
            subtitle: 'vacancy.createFunnel.modal.inputs.vacancyTemplate.subtitle',
            createVacancy: 'vacancy.createFunnel.modal.inputs.vacancyTemplate.createVacancy',
        },
    },
};

export interface CreateFunnelValues {
    funnelName: string;
    vacancyId?: string;
}

interface CreateFunnelFormProps {
    showVacancySelector: boolean;
    selectedVacancies: Vacancy[];
    handleVacancyChange: (vacancyId: string) => void;
    handleSubmit: FormEventHandler<HTMLFormElement>;
}

export const NEW_FUNNEL_KEY = 'NEW_FUNNEL';

const CreateFunnelForm: TranslatedComponent<CreateFunnelFormProps> = ({
    trls,
    selectedVacancies,
    showVacancySelector,
    handleVacancyChange,
    handleSubmit,
}) => {
    const options = useMemo<StaticDataFetcherItem[]>(() => {
        const newFunnelOption: StaticDataFetcherItem = {
            text: trls[TrlKeys.inputs.vacancyTemplate.createVacancy],
            value: NEW_FUNNEL_KEY,
        };

        const options: StaticDataFetcherItem[] = selectedVacancies.map((vacancy) => ({
            text: vacancy.name,
            value: vacancy.vacancyId.toString(),
        }));

        return [newFunnelOption, ...options];
    }, [trls, selectedVacancies]);

    const vacanciesDataProvider = useMemo(() => {
        return createStaticDataProvider(options);
    }, [options]);

    return (
        <Form id={FORM_NAME} name={FORM_NAME} onSubmit={handleSubmit}>
            <Field
                name="funnelName"
                render={({ input, meta }) => {
                    const error = (meta.error || meta.submitError) as string;
                    return (
                        <Input
                            {...input}
                            invalid={meta.touched && Boolean(error)}
                            errorMessage={getFunnelErrorMessage(error, trls)}
                            placeholder={trls[TrlKeys.inputs.funnelName.placeholder]}
                        />
                    );
                }}
                validate={funnelNameValidator}
            />

            {showVacancySelector && (
                <>
                    <VSpacing default={16} />
                    <Field
                        name="vacancyId"
                        render={({ input }) => (
                            <Select
                                {...input}
                                type="radio"
                                dataProvider={vacanciesDataProvider}
                                triggerProps={{
                                    label: trls[TrlKeys.inputs.vacancyTemplate.placeholder],
                                    size: 'medium',
                                    stretched: true,
                                }}
                                searchable
                                onChange={(newItem) => {
                                    const vacancyId = newItem?.value ?? '';
                                    input.onChange(vacancyId);
                                    handleVacancyChange(vacancyId);
                                }}
                                value={findStaticDataFetcherItemByValue(input.value as string, options)}
                            />
                        )}
                    />
                    <VSpacing default={8} />
                    <Text typography="label-3-regular" style="secondary">
                        {trls[TrlKeys.inputs.vacancyTemplate.subtitle]}
                    </Text>
                </>
            )}
        </Form>
    );
};

export default translation(CreateFunnelForm);
