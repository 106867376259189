import classnames from 'classnames';

import { Action, Link, Loader, Text, VSpacing } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';

import translation from 'src/components/translation';

import styles from './styles.less';

interface VacancyRowProps {
    vacancyId: number;
    name: string;
    description: string;
    onRemove?: (id: number) => void;
    isLoading?: boolean;
    disabled?: boolean;
}

const VacancyRow = ({ name, vacancyId, description, onRemove, isLoading, disabled }: VacancyRowProps) => {
    return (
        <div className={styles.root}>
            <div>
                <Link Element={SPALink} typography="label-3-regular" to={`/vacancy/${vacancyId}`} target="_blank">
                    {name}
                </Link>
                <VSpacing default={4} />
                <Text style="secondary" typography="label-3-regular">
                    {description}
                </Text>
            </div>
            {onRemove && (
                <div className={styles.containerIcon}>
                    <div className={classnames(styles.wrapperIcon, { [styles.loading]: isLoading })}>
                        {isLoading ? (
                            <Loader size={24} />
                        ) : (
                            <Action
                                disabled={disabled}
                                mode="secondary"
                                onClick={() => onRemove(vacancyId)}
                                icon={CrossOutlinedSize24}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default translation(VacancyRow);
