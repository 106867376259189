import { useEffect, useMemo, useRef, useState } from 'react';

import employerHiringInterviewArchiveButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/archive/employer_hiring_interview_archive_button_click';
import { employerHiringInterviewArchiveElementShownRaw } from '@hh.ru/analytics-js-events/build/xhh/employer/archive/employer_hiring_interview_archive_element_shown';
import {
    Action,
    BottomSheet,
    BottomSheetFooter,
    Button,
    Card,
    Checkbox,
    Input,
    Link as MagritteLink,
    Loader,
    Modal,
    NavigationBar,
    NumberInput,
    Text,
    useBreakpoint,
    ActionBar,
    createDataProvider,
    decorateWithResultProducer,
    decorateWithQueryTransformer,
    DataProviderResult,
    defaultFetcher,
} from '@hh.ru/magritte-ui';
import { MinusCircleOutlinedSize24, PlusCircleOutlinedSize24, CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import { updateUrl } from 'Modules/url';
import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { useNotification } from 'src/components/Notifications/Provider';
import SuggestWithDefaultErrorPlaceholder from 'src/components/SuggestWithDefaultErrorPlaceholder';
import translation from 'src/components/translation';
import { useSelectorNonNullable, useSelector } from 'src/hooks/useSelector';
import { StateId } from 'src/models/employerNegotiations/stateId.types';
import { HiringPlanType } from 'src/models/employerVacancies/vacancyHiringPlans';
import fetcher from 'src/utils/fetcher';
import { CancelCallback } from 'src/utils/suggest/buildDataProvider';

import styles from './hiring-survey.less';

const ANOTHER_CANDIDATE_ID = 'another';

const GET_HIRING_PLAN_URL = '/shards/employer/vacancies/hiring_plan/get_hiring_plan';
const UPDATE_HIRING_PLAN_URL = '/shards/employer/vacancies/hiring_plan/update_hiring_plan';
const GET_CANDIDATES_TO_SHOW = '/shards/negotiations/candidates_for_interview';

interface Candidate {
    id: string;
    title: string;
    firstName: string;
    middleName: string;
    lastName: string;
    hash: string;
    hired?: boolean;
}

const TrlKeys = {
    title: 'employerHiringStatus.positive.title',
    another: 'employerHiringStatus.positive.answer.another',
    continue: 'employerHiringStatus.continue',
    search: 'employerHiringStatus.search',
};

declare global {
    interface FetcherGetApi {
        [GET_CANDIDATES_TO_SHOW]: {
            queryParams: {
                vacancyId: number;
                excludeHired?: boolean;
            };
            response: {
                candidates: Candidate[];
            };
        };
        [GET_HIRING_PLAN_URL]: {
            queryParams: {
                vacancyId: string;
            };
            response: {
                hiringPlan: HiringPlanType;
            };
        };
    }
    interface FetcherPostApi {
        [UPDATE_HIRING_PLAN_URL]: {
            body: HiringPlanType;
            queryParams: {
                vacancyId: string;
            };
            response: void;
        };
    }
}

const onMoveCandidatesToHired = async (vacancyId: string, resumeHash: string[]) => {
    if (resumeHash.length > 0) {
        await fetcher.postFormData('/employer/negotiations/change_topic', {
            vacancyId,
            state: StateId.Hired,
            resumeHash,
        });
    }
};

const updateCandidatesWithoutHHResume = async (vacancyId: string, candidatesWithoutHHResume: number) => {
    await fetcher.post(
        UPDATE_HIRING_PLAN_URL,
        {
            hiredWithoutResumeCount: candidatesWithoutHHResume,
        },
        {
            params: {
                vacancyId,
            },
        }
    );
};

const getHiringPlan = (vacancyId: string, signal: AbortSignal) => {
    return fetcher.get(GET_HIRING_PLAN_URL, {
        params: { vacancyId },
        signal,
    });
};

interface HiringSurveyProps {
    visible: boolean;
    vacancyId: number;
    onClose: () => void;
    isVacancyArchived?: boolean;
    isVacancyView?: boolean;
    onTriggerUxFeedbackSurvey?: () => void;
}

interface CandidateItem {
    candidate?: Candidate;
    text: string;
    id: string;
}

interface DataProviderResponse {
    candidate?: Candidate;
    text: string;
}

const HiringSurvey: TranslatedComponent<HiringSurveyProps> = ({
    trls,
    visible,
    vacancyId,
    onClose,
    isVacancyArchived = false,
    isVacancyView = false,
    onTriggerUxFeedbackSurvey,
}) => {
    const [inputValue, setInputValue] = useState('');
    const candidatesRef = useRef<HTMLDivElement>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [candidates, setCandidates] = useState<Candidate[]>([]);
    const domain = useSelectorNonNullable((state) => state.topLevelDomain);
    const hiredWithoutResumeCount = useSelector(
        (state) => state.vacancyHiringPlans[vacancyId]?.[0].hiredWithoutResumeCount
    );
    const [hiredWithoutResumeState, setHiredWithoutResumeState] = useState<string>(`${hiredWithoutResumeCount || 0}`);
    const [isOtherCandidatesInputVisible, setOtherCandidatesInputVisible] = useState(!!hiredWithoutResumeCount);
    const [result, setResult] = useState<string[]>(hiredWithoutResumeCount ? [ANOTHER_CANDIDATE_ID] : []);
    const [isHiringPlanEnabledOnArchivedVacancy, setHiringPlanEnabledOnArchivedVacancy] = useState(false);
    const { addNotification } = useNotification();
    const { isMobile } = useBreakpoint();

    useEffect(() => {
        const abortController = new AbortController();

        async function prepareData() {
            let response;

            try {
                response = await Promise.all([
                    fetcher.get(GET_CANDIDATES_TO_SHOW, {
                        params: { vacancyId, excludeHired: !isVacancyArchived },
                        signal: abortController.signal,
                    }),
                    isVacancyArchived
                        ? getHiringPlan(String(vacancyId), abortController.signal)
                        : { hiringPlan: {} as HiringPlanType },
                ]);
            } catch (error) {
                if (fetcher.isCancel(error)) {
                    return;
                }
                console.error(error);
            } finally {
                setLoading(false);
            }
            if (response) {
                const [{ candidates }, { hiringPlan }] = response;

                if (candidates) {
                    setCandidates(candidates);

                    if (isVacancyArchived) {
                        setResult((result) => [
                            ...result,
                            ...candidates.filter((candidate) => candidate.hired).map(({ id }) => id),
                        ]);

                        if (candidatesRef.current) {
                            employerHiringInterviewArchiveElementShownRaw({
                                vacancyId: String(vacancyId),
                                resumeIdList: candidates.map(({ id }) => id).join(),
                            });
                        }
                    }
                }
                if (hiringPlan.plannedCount) {
                    setHiringPlanEnabledOnArchivedVacancy(true);
                }
                if (hiringPlan.hiredWithoutResumeCount) {
                    setHiredWithoutResumeState(String(hiringPlan.hiredWithoutResumeCount));
                    setResult([ANOTHER_CANDIDATE_ID]);
                    setOtherCandidatesInputVisible(true);
                }
            }
        }

        void prepareData();

        return () => {
            abortController.abort();
        };
    }, [vacancyId, isVacancyArchived]);

    const getCandidates = (url: string, onCancel: CancelCallback): Promise<DataProviderResponse[]> =>
        defaultFetcher<{ items: CandidateItem[] }>(url, onCancel).then(
            (data) => data?.items.map(({ candidate, text }) => ({ candidate, text })) ?? []
        );

    const formatter = (items: DataProviderResponse[] | null): DataProviderResult<Candidate> => {
        if (!items) {
            return [];
        }

        return items.map((item) => ({
            type: 'cells',
            items: [
                {
                    data: item.candidate,
                    value: item.text,
                },
            ],
        }));
    };

    const queryString = (q: string) => updateUrl('/shards/employer/vacancyresponses/by_name', { q, vacancyId });
    const decoratedFetcher = decorateWithQueryTransformer(getCandidates, queryString);
    const dataProvider = useMemo(
        () =>
            createDataProvider({
                fetcher: decorateWithResultProducer(decoratedFetcher, formatter),
                minCharsCount: 3,
            }),
        [decoratedFetcher]
    );

    const checkItem = (candidateId: string) => {
        if (result.includes(candidateId)) {
            setResult(result.filter((item) => item !== candidateId));
        } else {
            setResult([...result, candidateId]);
        }
    };

    const handleContinue = async () => {
        if (isVacancyArchived) {
            employerHiringInterviewArchiveButtonClick({ vacancyId: String(vacancyId), resumeIdList: result.join() });
        }
        if (isHiringPlanEnabledOnArchivedVacancy || !isVacancyArchived) {
            await Promise.all([
                onMoveCandidatesToHired(
                    String(vacancyId),
                    candidates.filter(({ id }) => result.includes(id)).map(({ hash }) => hash)
                ),
                updateCandidatesWithoutHHResume(
                    String(vacancyId),
                    isOtherCandidatesInputVisible ? Number(hiredWithoutResumeState) : 0
                ),
            ]).catch((error) => {
                defaultRequestErrorHandler(error, addNotification);
            });
        }
        if (isVacancyView) {
            onTriggerUxFeedbackSurvey && onTriggerUxFeedbackSurvey();
        }
        onClose();
    };

    const modalTitle = trls[TrlKeys.title];
    const modalLeftButtons = (
        <div className={styles.hiringSurveyFooter}>
            <div className={styles.otherCandidates}>
                <label className={styles.checkboxWrapper}>
                    <Checkbox
                        data-qa="hiring-survey-another"
                        value={ANOTHER_CANDIDATE_ID}
                        checked={isOtherCandidatesInputVisible}
                        onChange={() => {
                            checkItem(ANOTHER_CANDIDATE_ID);
                            setOtherCandidatesInputVisible((value) => !value);
                        }}
                    />
                    <Text>{format(trls[TrlKeys.another], { '{0}': domain })}</Text>
                </label>
                {isOtherCandidatesInputVisible && (
                    <div className={styles.otherCandidatesCountWrapper}>
                        <NumberInput
                            data-qa="hiring-survey-count"
                            allowNegative={false}
                            decimalLength={0}
                            value={hiredWithoutResumeState}
                            onChange={(value) => setHiredWithoutResumeState(value)}
                            onBlur={() =>
                                setHiredWithoutResumeState(
                                    hiredWithoutResumeState === '.' ? '0' : `${Number(hiredWithoutResumeState)}`
                                )
                            }
                        />
                        <Button
                            mode="tertiary"
                            style="accent"
                            onClick={() =>
                                setHiredWithoutResumeState((val) =>
                                    val === '' ? '0' : `${Number(val) > 0 ? Number(val) - 1 : val}`
                                )
                            }
                        >
                            <MinusCircleOutlinedSize24 />
                        </Button>
                        <Button
                            mode="tertiary"
                            style="accent"
                            onClick={() =>
                                setHiredWithoutResumeState((val) => (val === '' ? '0' : `${Number(val) + 1}`))
                            }
                        >
                            <PlusCircleOutlinedSize24 />
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
    const modalRightButtons = (
        <Button
            disabled={loading}
            mode="primary"
            style="accent"
            onClick={handleContinue}
            data-qa="hiring-survey-modal-submit"
        >
            {trls[TrlKeys.continue]}
        </Button>
    );
    const modalContent = (
        <div className={styles.hiringSurvey} ref={candidatesRef}>
            {loading ? (
                <Card paddingBottom={16}>
                    <Loader size={64} />
                </Card>
            ) : (
                <>
                    <SuggestWithDefaultErrorPlaceholder
                        onSelectValidator={(_: string, candidate) => {
                            if (candidate) {
                                if (!candidates.find(({ id }) => id === candidate.id)) {
                                    setCandidates([candidate, ...candidates]);
                                }
                                if (!result.includes(candidate.id)) {
                                    setResult([...result, candidate.id]);
                                }
                            }
                            setInputValue('');
                            return true;
                        }}
                        dataProvider={dataProvider}
                        input={{
                            component: Input,
                            props: {
                                value: inputValue,
                                size: 'medium',
                                clearable: true,
                                placeholder: trls[TrlKeys.search],
                                'data-qa': 'hiring-survey-suggest-input',
                            },
                        }}
                    />
                    <Card paddingTop={16}>
                        {candidates.map((candidate) => (
                            <Card key={candidate.id} stretched paddingBottom={8}>
                                <label className={styles.checkboxWrapper}>
                                    <Checkbox
                                        data-qa={`hiring-survey-candidate-${candidate.hash}`}
                                        value={candidate.id}
                                        checked={result.includes(candidate.id)}
                                        onChange={() => checkItem(candidate.id)}
                                    />
                                    <div className={styles.candidateWrapper}>
                                        <MagritteLink
                                            Element="a"
                                            target="_blank"
                                            style="accent"
                                            href={`/resume/${candidate.hash}?hhtmFromLabel=hiring_survey`}
                                        >
                                            <Text typography="paragraph-2-regular">
                                                {candidate.lastName} {candidate.firstName} {candidate.middleName}
                                            </Text>
                                        </MagritteLink>
                                        <Text typography="paragraph-2-regular">{candidate.title}</Text>
                                    </div>
                                </label>
                            </Card>
                        ))}
                    </Card>
                </>
            )}
        </div>
    );

    return (
        <>
            <Modal
                actions={<Action mode="secondary" onClick={onClose} icon={CrossOutlinedSize24} />}
                visible={visible}
                title={modalTitle}
                titleSize="medium"
                footer={<ActionBar primaryActions={modalRightButtons} secondaryActions={modalLeftButtons} />}
                onClose={onClose}
            >
                {modalContent}
            </Modal>
            <BottomSheet
                visible={isMobile && visible}
                header={<NavigationBar title={modalTitle} />}
                footer={
                    <BottomSheetFooter>
                        {modalLeftButtons}
                        {modalRightButtons}
                    </BottomSheetFooter>
                }
                onClose={onClose}
            >
                {modalContent}
            </BottomSheet>
        </>
    );
};

export default translation(HiringSurvey);
