import { PropsWithChildren, useState } from 'react';
import classnames from 'classnames';

import Analytics from '@hh.ru/analytics-js';
import {
    Action,
    BottomSheet,
    BottomSheetFooter,
    Modal,
    NavigationBar,
    Title,
    useBreakpoint,
    Text,
    Button,
    ActionBar,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24, CheckCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Notification from 'src/components/Notifications/Notification';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import useAnalyticsEventShownOnce from 'src/hooks/useAnalyticsEventShownOnce';

import RatingChooser from 'src/components/VacancyModals/VacancyAdviceModal/RatingChooser';
import VacancyAdvicesModalTitle from 'src/components/VacancyModals/VacancyAdviceModal/VacancyAdvicesModalTitle';
import {
    Rating,
    VACANCY_ADVICE_MODAL_SOURCE_EVENT,
    VacancyAdviceModalEventType,
} from 'src/components/VacancyModals/VacancyAdviceModal/constants';

import styles from './vacancy-advice-modal-magritte.less';

interface VacancyAdviceModalMagritteProps extends PropsWithChildren {
    numOfAdvices: number;
    visible: boolean;
    titleDescription: string;
    vacancyId: number;
    onClose: () => void;
}

const TrlKeys = {
    title: 'employer.myVacancies.advice.feedback.modal.title',
    feedbackText: 'employer.myVacancies.advice.feedback.modal.parent.text',
    sendButton: 'employer.myVacancies.advice.feedback.modal.buttons.send',
    backButton: 'Back',
    successNotification: 'employer.myVacancies.advice.feedback.modal.successNotification',
};

const onSetRatingEvent = (rating: Rating | null) => {
    const setRatingVacancyAdvicesModalEvent = new CustomEvent(VACANCY_ADVICE_MODAL_SOURCE_EVENT, {
        detail: { type: VacancyAdviceModalEventType.SetRating, rating },
    });
    window.dispatchEvent(setRatingVacancyAdvicesModalEvent);
};

const VacancyAdviceModalMagritte: TranslatedComponent<VacancyAdviceModalMagritteProps> = ({
    numOfAdvices,
    visible,
    titleDescription,
    vacancyId,
    onClose,
    children,
    trls,
}) => {
    const { isXS } = useBreakpoint();
    const [selectedRating, setSelectedRating] = useState<Rating | null>(null);
    const { addNotification } = useNotification();

    const onCloseModal = () => {
        Analytics.sendHHEvent('employer_vacancies_advice_modal_close', {
            vacancyId,
        });
        onClose();
    };

    const onCancelChoiceRating = () => {
        Analytics.sendHHEventButtonClick('employer_vacancies_advice_feedback_cancel', {
            vacancyId,
        });
        onSetRatingEvent(null);
        setSelectedRating(null);
    };

    const onSendFeedback = () => {
        const sendFeedbackEvent = new CustomEvent(VACANCY_ADVICE_MODAL_SOURCE_EVENT, {
            detail: { type: VacancyAdviceModalEventType.SendFeedback },
        });
        window.dispatchEvent(sendFeedbackEvent);
        addNotification(Notification, {
            props: {
                addon: <CheckCircleFilledSize24 initialColor="positive" />,
                children: trls[TrlKeys.successNotification],
                showClose: true,
            },
        });
        onCloseModal();
    };

    const onSelectRating = (rating: Rating) => {
        Analytics.sendHHEventButtonClick('employer_vacancies_advice_feedback_rating_item', {
            vacancyId,
            rating,
        });
        onSetRatingEvent(rating);
        setSelectedRating(rating);
    };

    const feedbackTextRef = useAnalyticsEventShownOnce({
        name: 'employer_vacancies_advice_feedback_button',
        vacancyId,
    });

    const leftButtons = (
        <span ref={feedbackTextRef}>
            {selectedRating ? (
                <Button mode="secondary" style="accent" onClick={onCancelChoiceRating}>
                    {trls[TrlKeys.backButton]}
                </Button>
            ) : (
                <Text>{trls[TrlKeys.feedbackText]}</Text>
            )}
        </span>
    );

    const rightButtons = selectedRating ? (
        <Button mode="primary" style="accent" onClick={onSendFeedback}>
            {trls[TrlKeys.sendButton]}
        </Button>
    ) : (
        <RatingChooser selected={selectedRating} onSelect={onSelectRating} />
    );

    const modalTitle = selectedRating ? (
        <Title size="medium" Element="h4">
            {trls[TrlKeys.title]}
        </Title>
    ) : (
        <VacancyAdvicesModalTitle numOfAdvices={numOfAdvices} vacancyId={vacancyId} />
    );

    const subtitle = selectedRating ? undefined : (
        <Text typography="label-2-regular" style="secondary">
            {titleDescription}
        </Text>
    );

    return (
        <>
            <Modal
                size="large"
                visible={visible}
                onClose={onCloseModal}
                titleDescription={subtitle}
                titleDescriptionStyle="secondary"
                actions={<Action mode="secondary" onClick={onClose} icon={CrossOutlinedSize24} />}
                title={modalTitle}
                footer={<ActionBar primaryActions={rightButtons} secondaryActions={leftButtons} />}
            >
                {children}
            </Modal>
            <BottomSheet
                header={
                    <NavigationBar
                        title={<div className={styles.vacancyAdviceModalBottomSheetTitle}>{modalTitle}</div>}
                        subtitle={subtitle}
                        showDivider="always"
                        right={<Action icon={CrossOutlinedSize24} onClick={onClose} />}
                    />
                }
                visible={visible}
                onClose={onCloseModal}
                showDivider="with-scroll"
                footer={
                    <BottomSheetFooter>
                        <div
                            className={classnames(styles.vacancyAdviceModalBottomSheetFooter, {
                                [styles.vacancyAdviceModalBottomSheetFooterColumn]: isXS && !selectedRating,
                            })}
                        >
                            {leftButtons}
                            {rightButtons}
                        </div>
                    </BottomSheetFooter>
                }
            >
                {children}
            </BottomSheet>
        </>
    );
};

export default translation(VacancyAdviceModalMagritte);
