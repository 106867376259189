import classnames from 'classnames';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { ServiceItemCode } from 'src/models/price/productCodes';
import { PublicationBillingCode } from 'src/models/prolongateInfo';

import styles from './vacancy-item.less';

export interface VacancyItemProps {
    billingCode: PublicationBillingCode;
}

const IconProperties: Record<PublicationBillingCode, string> = {
    [ServiceItemCode.Standart]: styles.standart,
    [ServiceItemCode.StandartPlus]: styles.standartPlus,
    [ServiceItemCode.Premium]: styles.premium,
    [ServiceItemCode.FreeRegionalVacancy]: styles.free,
    [ServiceItemCode.FreeVacancy]: styles.free,
    [ServiceItemCode.Anonymous]: styles.anonymous,
    [ServiceItemCode.Advertizing]: styles.advertising,
    [ServiceItemCode.ZpPromo]: styles.free,
    [ServiceItemCode.ZpBusiness]: styles.business,
    [ServiceItemCode.ZpAnonymous]: styles.anonymous,
    [ServiceItemCode.ZpSprint]: styles.premium,
};

const VacancyItemIcon: TranslatedComponent<VacancyItemProps> = ({ billingCode }) => {
    return <div className={classnames(styles.icon, IconProperties[billingCode])} />;
};

export default translation(VacancyItemIcon);
