import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { defaultMagritteRequestErrorHandler } from 'src/api/notifications/defaultRequestErrorHandler';
import { useNotification } from 'src/components/Notifications/Provider';
import { StageType } from 'src/components/VacancyFunnel/types';
import { GET_FUNNEL_URL } from 'src/components/VacancyResponsesSidebar/fetchers';
import useOnOffState from 'src/hooks/useOnOffState';
import { addVacancyFunnelStages } from 'src/models/vacancyFunnel';
import fetcher from 'src/utils/fetcher';

type UseFunnelModal = (params: { selectedFunnelId: string }) => {
    funnelModalOpen: boolean;
    funnelLoading: boolean;
    funnelStage: StageType;
    setFunnelStage: (stage: StageType) => void;
    handleShowFunnel: VoidFunction;
    closeFunnelModal: VoidFunction;
};

const useFunnelModal: UseFunnelModal = ({ selectedFunnelId }) => {
    const abortControllerRef = useRef<AbortController | null>(null);
    const { addNotification } = useNotification();
    const dispatch = useDispatch();
    const [funnelModalOpen, openFunnelModal, closeFunnelModal] = useOnOffState(false);
    const [funnelLoading, setFunnelLoading] = useState(false);
    const [funnelStage, setFunnelStage] = useState<StageType>('inProgress');

    const handleShowFunnel = async () => {
        try {
            setFunnelLoading(true);

            const controller = new AbortController();
            abortControllerRef.current?.abort();
            abortControllerRef.current = controller;

            const response = await fetcher.get(GET_FUNNEL_URL, {
                params: { funnelId: Number(selectedFunnelId) },
                signal: abortControllerRef.current.signal,
            });
            dispatch(addVacancyFunnelStages(response));
            openFunnelModal();
            setFunnelLoading(false);
        } catch (error) {
            if (!fetcher.isCancel) {
                setFunnelLoading(false);
                defaultMagritteRequestErrorHandler(error, addNotification);
                console.error(error);
            }
        }
    };

    useEffect(() => {
        return () => {
            abortControllerRef.current?.abort();
        };
    }, []);

    return { funnelModalOpen, funnelLoading, funnelStage, handleShowFunnel, closeFunnelModal, setFunnelStage };
};

export default useFunnelModal;
