import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Alert, Button } from '@hh.ru/magritte-ui';
import { CheckCircleFilledSize24, ExclamationTriangleFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { usePush } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { defaultMagritteRequestErrorHandler } from 'src/api/notifications/defaultRequestErrorHandler';
import { CRUD_FUNNEL_URL } from 'src/components/Employer/Funnel/fetchers';
import { removeFunnels } from 'src/components/Employer/Funnel/utils';
import Notification from 'src/components/Notifications/Notification';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import fetcher from 'src/utils/fetcher';

const TrlKeys = {
    title: 'vacancyFunnel.modals.deleteFunnel.title',
    subtitle: 'vacancyFunnel.modals.deleteFunnel.subtitle',
    buttons: {
        submit: 'vacancyFunnel.modals.deleteFunnel.buttons.submit',
        cancel: 'vacancyFunnel.modals.deleteFunnel.buttons.cancel',
    },
    feedback: {
        success: 'vacancyFunnel.modals.deleteFunnel.success',
    },
};

interface DeleteFunnelModalProps {
    isVisible: boolean;
    handleCloseModal: () => void;
    funnelId: number;
}

const DeleteFunnelModal: TranslatedComponent<DeleteFunnelModalProps> = ({
    funnelId,
    isVisible,
    trls,
    handleCloseModal,
}) => {
    const [submitting, setSubmitting] = useState(false);
    const { addNotification } = useNotification();
    const abortControllerRef = useRef<AbortController | null>(null);
    const push = usePush();
    const dispatch = useDispatch();
    const currentManagerId = useSelector((state) => state.employerManager?.id) || '';

    const onSuccess = () => {
        setSubmitting(false);
        handleCloseModal();
        void dispatch(removeFunnels([funnelId], currentManagerId, push));

        addNotification(Notification, {
            props: {
                children: trls[TrlKeys.feedback.success],
                addon: <CheckCircleFilledSize24 initialColor="positive" />,
            },
        });
    };

    const onError = (error: unknown) => {
        setSubmitting(false);
        defaultMagritteRequestErrorHandler(error, addNotification);
    };

    const handleDelete = async () => {
        if (submitting) {
            return;
        }

        setSubmitting(true);

        try {
            abortControllerRef.current?.abort();
            const abortController = new AbortController();
            abortControllerRef.current = abortController;

            await fetcher.delete(CRUD_FUNNEL_URL, {
                params: { funnelId },
                signal: abortControllerRef.current?.signal,
            });

            onSuccess();
        } catch (error) {
            if (fetcher.isCancel(error)) {
                return;
            }

            onError(error);
        }
    };

    useEffect(() => {
        return () => {
            abortControllerRef.current?.abort();
        };
    }, []);

    return (
        <Alert
            iconStyle="warning"
            icon={<ExclamationTriangleFilledSize24 />}
            buttons={
                <>
                    <Button mode="primary" style="accent" stretched onClick={handleDelete} loading={submitting}>
                        {trls[TrlKeys.buttons.submit]}
                    </Button>
                    <Button mode="secondary" style="accent" stretched onClick={handleCloseModal}>
                        {trls[TrlKeys.buttons.cancel]}
                    </Button>
                </>
            }
            visible={isVisible}
            onClose={handleCloseModal}
            title={trls[TrlKeys.title]}
            description={trls[TrlKeys.subtitle]}
            layout="vertical"
        />
    );
};

export default translation(DeleteFunnelModal);
