import { FormEventHandler, useMemo } from 'react';
import { Field } from 'react-final-form';

import { Cell, CellText, Select, SelectProps, Text, VSpacing, createDataProvider } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import { createVacanciesFetcher } from 'src/components/Employer/Funnel/fetchers/vacanciesFetcher';
import {
    vacanciesToFunnelValidator,
    AddVacanciesToFunnelValidationError,
} from 'src/components/Employer/Funnel/validators';
import Form from 'src/components/Form';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { Vacancy } from 'src/models/employerVacancies/vacancy.types';
import { VacancyFunnel } from 'src/models/vacancyFunnel';

export const FORM_NAME = 'add-vacancies-to-funnel';

const TrlKeys = {
    hint: 'vacancyFunnel.modals.addVacancies.inputHint',
    placeholder: 'vacancyFunnel.modals.addVacancies.inputPlaceholder',
    optionsSubtitle: 'vacancyFunnel.modals.addVacancies.options.subtitle',
    postfix: 'vacancyFunnel.modals.addVacancies.input.postfix',
    errors: {
        title: 'vacancyFunnel.modals.addVacancies.input.errors.title',
        description: 'vacancyFunnel.modals.addVacancies.input.errors.description',
        reloadButton: 'vacancyFunnel.modals.addVacancies.input.errors.reloadButton',
        emptyList: 'vacancyFunnel.modals.addVacancies.input.errors.emptyList',
    },
};

export interface AddVacanciesToFunnelValues {
    vacancies: Array<Vacancy & { value: string }>;
}

interface SelectOption {
    value: string;
    text?: string;
    funnel?: VacancyFunnel;
}

interface AddVacanciesToFunnelFormProps {
    handleSubmit: FormEventHandler<HTMLFormElement>;
}

const AddVacanciesToFunnelForm: TranslatedComponent<AddVacanciesToFunnelFormProps> = ({ trls, handleSubmit }) => {
    const currentManagerId = useSelector((state) => state.employerManager?.id);

    const renderOption: SelectProps<true, SelectOption>['renderDesktopItem'] = ({ data, input }) => {
        const { text, funnel } = data;

        return (
            <Cell align="top" left={input}>
                <CellText maxLines={1}>{text}</CellText>
                {funnel && !funnel?.defaultFunnel && (
                    <CellText type="description">
                        {format(trls[TrlKeys.optionsSubtitle], { '{0}': funnel.name })}
                    </CellText>
                )}
            </Cell>
        );
    };

    const vacanciesDataProvider = useMemo(
        () =>
            createDataProvider({
                minCharsCount: 0,
                fetcher: createVacanciesFetcher(currentManagerId),
            }),
        [currentManagerId]
    );

    return (
        <Form id={FORM_NAME} name={FORM_NAME} onSubmit={handleSubmit}>
            <Field
                name="vacancies"
                render={({ input, meta }) => {
                    const hasError = meta.error === AddVacanciesToFunnelValidationError.EMPTY_LIST;

                    return (
                        <Select
                            {...input}
                            name="vacancies"
                            type="checkbox"
                            multiple
                            searchable
                            dataProvider={vacanciesDataProvider}
                            triggerProps={{
                                label: trls[TrlKeys.placeholder],
                                size: 'medium',
                                stretched: true,
                                trls: {
                                    postfix: trls[TrlKeys.postfix],
                                },
                                invalid: meta.touched && hasError,
                                errorMessage: trls[TrlKeys.errors.emptyList],
                            }}
                            innerErrorTrls={{
                                title: trls[TrlKeys.errors.title],
                                description: trls[TrlKeys.errors.description],
                                reloadButton: trls[TrlKeys.errors.reloadButton],
                            }}
                            renderDesktopItem={renderOption}
                            renderMobileItem={renderOption}
                        />
                    );
                }}
                validate={vacanciesToFunnelValidator}
            />
            <VSpacing default={8} />
            <Text typography="label-3-regular" style="secondary">
                {trls[TrlKeys.hint]}
            </Text>
        </Form>
    );
};

export default translation(AddVacanciesToFunnelForm);
