import { ReactElement } from 'react';

import { TriggerType } from 'src/components/ControlsTrigger';

import getMenuButtonIconByName from 'src/components/VacancyActions/getMenuButtonIconByName';

const getMenuButtonIconProps = ({
    triggerType,
    triggerName,
    icon,
}: {
    triggerType?: TriggerType;
    triggerName?: string;
    icon: ReactElement;
}): { icon: ReactElement } | undefined => {
    if (
        triggerName &&
        [
            'archive',
            'edit',
            'auto-prolongation-toggle',
            'duplicate',
            'upgrade',
            'option-toggle',
            'videointerview',
            'hide-archived-vacancies',
            'restore-archived-vacancies',
        ].includes(triggerName) &&
        [TriggerType.Button, TriggerType.Menu].includes(triggerType)
    ) {
        const icon = getMenuButtonIconByName(triggerName);
        return icon ? { icon } : undefined;
    }

    if (
        !icon ||
        ![TriggerType.ButtonMenu, TriggerType.ActionList].includes(triggerType) ||
        ![
            'archive',
            'auto-update',
            'auto-prolongation-toggle',
            'planned-publication-preview',
            'auto-publication-continue-payment-action',
            'cancelAutoPublication',
            'cancel-scheduled-publication',
            'delete-draft',
            'draft-publish-scheduled',
            'duplicate',
            'edit',
            'edit-auto-update',
            'edit-draft',
            'option-toggle',
            'statistics',
            'transfer',
            'transfer-draft',
            'transfer-archived-vacancies',
            'hide-archived-vacancies',
            'restore-archived-vacancies',
            'upgrade',
            'videointerview',
            'change-visibility',
            'share',
            'draft-preview',
        ].includes(triggerName)
    ) {
        return undefined;
    }

    return { icon };
};

export default getMenuButtonIconProps;
