import { useSelector, useSelectorNonNullable } from 'src/hooks/useSelector';

const useIsViewYourOwnDrafts = (): boolean => {
    const activeGroupId = useSelectorNonNullable(
        ({ managerVacanciesActiveFilteredGroupId }) => managerVacanciesActiveFilteredGroupId
    );
    const employerManagerId = useSelector((state) => state?.employerManager?.id);

    return activeGroupId === employerManagerId || activeGroupId === 'all';
};

export default useIsViewYourOwnDrafts;
