import { FunnelInfo } from 'src/components/VacancyCreate/sections/PublicationType/FunnelSelector/fetchFunnelsList';

export const CRUD_FUNNEL_URL = '/shards/employer/funnel';
export const UPDATE_FUNNEL_VACANCIES_URL = '/shards/employer/funnel/vacancies';

export interface CreateFunnelParams {
    funnelName: string;
    configFunnelId?: number;
    vacancyIds: number[];
}

export interface DeleteFunnelParams {
    funnelId: number;
}

export interface UpdateFunnelParams {
    funnelId: number;
    funnelName: string;
}

export interface UpdateFunnelVacanciesParams {
    funnelId: number | 'default';
    vacancyIds: number[];
}

declare global {
    interface FetcherPostApi {
        [CRUD_FUNNEL_URL]: {
            queryParams: void;
            body: CreateFunnelParams;
            response: FunnelInfo;
        };
    }
}

declare global {
    interface FetcherDeleteApi {
        [CRUD_FUNNEL_URL]: {
            queryParams: { funnelId: number };
            body: DeleteFunnelParams;
            response: FunnelInfo;
        };
    }
}

declare global {
    interface FetcherPutApi {
        [CRUD_FUNNEL_URL]: {
            queryParams: void;
            body: UpdateFunnelParams;
            response: FunnelInfo;
        };
    }
}

declare global {
    interface FetcherPutApi {
        [UPDATE_FUNNEL_VACANCIES_URL]: {
            queryParams: void;
            body: UpdateFunnelVacanciesParams;
            response: void;
        };
    }
}
