import { Dispatch, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';

import { LangTrls } from 'bloko/common/hooks/useTranslations';

import { useNotification } from 'src/components/Notifications/Provider';
import vacancyActionErrorNotification from 'src/components/VacancyActions/VacancyActionError';
import fetchProlongateInfo from 'src/components/VacancyActions/fetchProlongateInfo';
import { validateResponse } from 'src/components/VacancyActions/risePageUtils';
import { AfterSuccessAction } from 'src/components/VacancyModals/Prolongation';
import { ADD_MODAL, MODAL_PROLONGATE } from 'src/components/VacancyModals/constants';
import { setRiseErrorStatus, resetRiseValues } from 'src/models/employerVacanciesRise';

import { useIsZarplataPlatform } from 'src/hooks/usePlatform';

interface UseProlongateAction {
    dispatchModal: Dispatch<AnyAction>;
    freeRenewalParameters: {
        lastActivationTimeIso: string | null;
        intervalMinutes: string | null;
    };
    loadingCallback?: (bool: boolean) => void;
    onClose?: VoidFunction;
    trls: LangTrls;
}

interface LoadProlongateInfoProps {
    vacanciesIds: number[];
    afterSuccessAction: AfterSuccessAction;
    additionalAnalyticsParams?: Record<string, string>;
}

const useProlongateAction = ({
    dispatchModal,
    freeRenewalParameters,
    loadingCallback,
    onClose,
    trls,
}: UseProlongateAction) => {
    const dispatch = useDispatch();
    const { addNotification } = useNotification();

    const isZpPlatform = useIsZarplataPlatform();

    const openModal = useCallback(
        (
            showRegularButton: boolean,
            vacanciesIds: number[],
            afterSuccessAction: AfterSuccessAction,
            additionalAnalyticsParams?: Record<string, string>
        ) => {
            dispatchModal({
                type: ADD_MODAL,
                payload: {
                    modalType: MODAL_PROLONGATE,
                    data: {
                        afterSuccessAction,
                        freeRenewalParameters,
                        showRegularButton,
                        vacanciesIds,
                        additionalAnalyticsParams,
                    },
                },
            });
            onClose && onClose();
        },
        [dispatchModal, freeRenewalParameters, onClose]
    );

    const loadProlongateInfo = useCallback(
        ({ vacanciesIds, afterSuccessAction, additionalAnalyticsParams }: LoadProlongateInfoProps) => {
            loadingCallback?.(true);

            // Если выбрать сначала вакансию с ошибкой, не закрывать снекбар, а затем сразу открыть вакансию без ошибки,
            // то без этой строчки будет открыта и модалка, и снекбар
            dispatch(resetRiseValues());

            return dispatch(fetchProlongateInfo({ vacanciesIds })).then(
                (prolongateInfo) => {
                    loadingCallback?.(false);

                    const error = validateResponse(trls, prolongateInfo.unavailable, vacanciesIds);
                    if (error) {
                        dispatch(setRiseErrorStatus({ message: error }));
                        return;
                    }

                    openModal(!isZpPlatform, vacanciesIds, afterSuccessAction, additionalAnalyticsParams);
                },
                () => {
                    addNotification(vacancyActionErrorNotification);
                    loadingCallback?.(false);
                }
            );
        },
        [loadingCallback, dispatch, trls, openModal, isZpPlatform, addNotification]
    );

    return loadProlongateInfo;
};

export default useProlongateAction;
