import { useEffect, useRef } from 'react';
import { Config } from 'final-form';

import { CheckCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { usePush } from '@hh.ru/redux-spa-middleware';
import format from 'bloko/common/format';
import { LangTrls } from 'bloko/common/hooks/useTranslations';

import { CreateFunnelValues } from 'src/components/Employer/Funnel/components/CreateFunnelForm';
import { CRUD_FUNNEL_URL, CreateFunnelParams } from 'src/components/Employer/Funnel/fetchers';
import { handleResponseErrors } from 'src/components/Employer/Funnel/utils';
import Notification from 'src/components/Notifications/Notification';
import { useNotification } from 'src/components/Notifications/Provider';
import { FunnelInfo } from 'src/components/VacancyCreate/sections/PublicationType/FunnelSelector/fetchFunnelsList';
import { COMBINED_VACANCIES_FILTERED_GROUP_ID } from 'src/models/employerVacancies/vacancy.types';
import fetcher from 'src/utils/fetcher';

const TrlKeys = {
    feedback: {
        successOne: 'vacancy.createFunnel.modal.success.one',
        successMany: 'vacancy.createFunnel.modal.success.many',
    },
};

type UseCreateFunnel = (params: {
    selectedVacancyIds: number[];
    trls: LangTrls;
    selectedFunnelId: string;
    handleCloseModal: VoidFunction;
}) => {
    onSubmit: Config<CreateFunnelValues, CreateFunnelValues>['onSubmit'];
};

const useCreateFunnel: UseCreateFunnel = ({ selectedVacancyIds, trls, selectedFunnelId, handleCloseModal }) => {
    const { addNotification } = useNotification();
    const abortControllerRef = useRef<AbortController | null>(null);
    const push = usePush();

    const showNotification = (funnel: FunnelInfo) => {
        addNotification(Notification, {
            props: {
                children: format(
                    selectedVacancyIds.length === 1
                        ? trls[TrlKeys.feedback.successOne]
                        : trls[TrlKeys.feedback.successMany],
                    {
                        '{0}': funnel.name,
                    }
                ),
                addon: <CheckCircleFilledSize24 initialColor="positive" />,
            },
        });
    };

    const handleResult = (funnel: FunnelInfo) => {
        showNotification(funnel);
        handleCloseModal();
        push(`/employer/vacancies?groupId=${COMBINED_VACANCIES_FILTERED_GROUP_ID}`);
    };

    const handleError = (error: unknown): Record<string, string> => {
        if (fetcher.isCancel(error)) {
            return {};
        }

        if (!fetcher.isAxiosError(error)) {
            console.error(error);
            return {};
        }

        return handleResponseErrors(error, addNotification);
    };

    const getRequestParams = (values: CreateFunnelValues): CreateFunnelParams => {
        const params: CreateFunnelParams = {
            funnelName: values.funnelName,
            vacancyIds: selectedVacancyIds,
        };

        if (selectedFunnelId) {
            params.configFunnelId = Number(selectedFunnelId);
        }

        return params;
    };

    const onSubmit = async (values: CreateFunnelValues): Promise<Record<string, string>> => {
        try {
            abortControllerRef.current?.abort();
            const abortController = new AbortController();
            abortControllerRef.current = abortController;

            const response = await fetcher.post(CRUD_FUNNEL_URL, getRequestParams(values), {
                signal: abortControllerRef.current?.signal,
            });

            handleResult(response.data);
        } catch (error) {
            return handleError(error);
        }

        return {};
    };

    useEffect(() => {
        return () => {
            abortControllerRef.current?.abort();
        };
    }, []);

    return { onSubmit };
};

export default useCreateFunnel;
