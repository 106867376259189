import { Link } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import format from 'bloko/common/format';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { updateUrl } from 'Modules/url';
import translation from 'src/components/translation';

const TrlKeys = {
    billNumber: {
        nominative: 'employer.vacancy.draft.autoPublication.billNumber',
        genitive: 'employer.vacancy.draft.autoPublication.billNumber.genitive',
    },
};

interface AutoPublicationBillInfo {
    billUid: string;
    cartId: number;
    useGenitive?: boolean;
}

const AutoPublicationBillInfo: TranslatedComponent<AutoPublicationBillInfo> = ({
    trls,
    billUid,
    cartId,
    useGenitive,
}) => (
    <Link Element={SPALink} typography="label-3-regular" to={updateUrl('/employer/carts', { cartId })}>
        {format(trls[TrlKeys.billNumber[useGenitive ? 'genitive' : 'nominative']], {
            '{}': billUid,
        })}
    </Link>
);

export default translation(AutoPublicationBillInfo);
