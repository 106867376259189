import { useCallback, useEffect, useState } from 'react';

import CustomSelect, {
    CustomSelectLayer,
    CustomSelectOption,
    CustomSelectOptionSecondaryText,
} from 'bloko/blocks/customSelect';
import { formatToReactComponent } from 'bloko/common/trl';

import FormatMoney from 'src/components/FormatMoney';
import translation from 'src/components/translation';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const ACCOUNT_FORMAT_STRING = `{0}, {1}${NON_BREAKING_SPACE}— {2}`;

const PayerAccountSelect = ({ accounts, minimumValueInCents, value, onChange, trls, ...selectProps }) => {
    const [selectedAccount, setSelectedAccount] = useState(value);

    const selectItem = useCallback(
        (selected) => {
            setSelectedAccount(selected);
            onChange(selected);
        },
        [onChange, setSelectedAccount]
    );

    useEffect(() => {
        if (!selectedAccount) {
            return;
        }

        const isSelectedValid = accounts.some(({ id, availableMoney }) => {
            return id === selectedAccount && availableMoney * 100 >= minimumValueInCents;
        });

        if (!isSelectedValid) {
            selectItem(null);
        }
    }, [minimumValueInCents, selectItem, accounts, selectedAccount]);

    return (
        <CustomSelect
            {...selectProps}
            emptyPlaceholder={trls[PayerAccountSelect.trls.placeholder]}
            value={selectedAccount}
            onChange={selectItem}
            layer={CustomSelectLayer.AboveOverlayContent}
            title={trls[PayerAccountSelect.trls.account]}
        >
            {accounts.map(({ id, name, number, availableMoney, payerName, currency }) => (
                <CustomSelectOption
                    key={id}
                    value={id}
                    data-item-value={id}
                    disabled={availableMoney * 100 < minimumValueInCents}
                >
                    {formatToReactComponent(ACCOUNT_FORMAT_STRING, {
                        '{0}': name,
                        '{1}': number,
                        '{2}': <FormatMoney currency={currency}>{availableMoney}</FormatMoney>,
                    })}
                    <CustomSelectOptionSecondaryText>{payerName}</CustomSelectOptionSecondaryText>
                </CustomSelectOption>
            ))}
        </CustomSelect>
    );
};

PayerAccountSelect.trls = {
    placeholder: 'employer.accountSelect.placeholder',
    account: 'VacancyCreate.PublicationType.Promo.BuyAndPublishPromo.account',
};

export default translation(PayerAccountSelect);
