import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Alert, Button } from '@hh.ru/magritte-ui';
import { CheckCircleFilledSize24, ExclamationTriangleFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { usePush } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { defaultMagritteRequestErrorHandler } from 'src/api/notifications/defaultRequestErrorHandler';
import { UPDATE_FUNNEL_VACANCIES_URL } from 'src/components/Employer/Funnel/fetchers';
import { removeVacanciesFromFunnels } from 'src/components/Employer/Funnel/utils';
import Notification from 'src/components/Notifications/Notification';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import fetcher from 'src/utils/fetcher';

const TrlKeys = {
    titleOne: 'vacancyFunnel.modals.deleteVacanciesFromFunnel.title.one',
    titleMany: 'vacancyFunnel.modals.deleteVacanciesFromFunnel.title.many',
    subtitleOne: 'vacancyFunnel.modals.deleteVacanciesFromFunnel.subtitle.one',
    subtitleMany: 'vacancyFunnel.modals.deleteVacanciesFromFunnel.subtitle.many',
    buttons: {
        submit: 'vacancyFunnel.modals.deleteVacanciesFromFunnel.buttons.submit',
        cancel: 'vacancyFunnel.modals.deleteVacanciesFromFunnel.buttons.cancel',
    },
    feedback: {
        successOne: 'vacancyFunnel.modals.deleteVacanciesFromFunnel.success.one',
        successMany: 'vacancyFunnel.modals.deleteVacanciesFromFunnel.success.many',
    },
};

interface DeleteVacanciesFromFunnelModalProps {
    isVisible: boolean;
    handleCloseModal: () => void;
    selectedVacancyIds: number[];
    afterSuccessAction?: VoidFunction;
}

const DeleteVacanciesFromFunnelModal: TranslatedComponent<DeleteVacanciesFromFunnelModalProps> = ({
    selectedVacancyIds,
    isVisible,
    trls,
    afterSuccessAction,
    handleCloseModal,
}) => {
    const [submitting, setSubmitting] = useState(false);
    const { addNotification } = useNotification();
    const abortControllerRef = useRef<AbortController | null>(null);
    const push = usePush();
    const dispatch = useDispatch();
    const currentManagerId = useSelector((state) => state.employerManager?.id) || '';

    const isOneSelectedVacancy = selectedVacancyIds.length === 1;

    const onSuccess = () => {
        setSubmitting(false);
        handleCloseModal();
        void dispatch(removeVacanciesFromFunnels(selectedVacancyIds, currentManagerId, push));
        afterSuccessAction?.();

        addNotification(Notification, {
            props: {
                children: isOneSelectedVacancy ? trls[TrlKeys.feedback.successOne] : trls[TrlKeys.feedback.successMany],
                addon: <CheckCircleFilledSize24 initialColor="positive" />,
            },
        });
    };

    const onError = (error: unknown) => {
        setSubmitting(false);
        defaultMagritteRequestErrorHandler(error, addNotification);
    };

    const handleDelete = async () => {
        if (submitting) {
            return;
        }

        setSubmitting(true);

        try {
            abortControllerRef.current?.abort();
            const abortController = new AbortController();
            abortControllerRef.current = abortController;

            await fetcher.put(
                UPDATE_FUNNEL_VACANCIES_URL,
                { funnelId: 'default', vacancyIds: selectedVacancyIds },
                {
                    signal: abortControllerRef.current?.signal,
                }
            );

            onSuccess();
        } catch (error) {
            if (fetcher.isCancel(error)) {
                return;
            }

            onError(error);
        }
    };

    useEffect(() => {
        return () => {
            abortControllerRef.current?.abort();
        };
    }, []);

    return (
        <Alert
            iconStyle="warning"
            icon={<ExclamationTriangleFilledSize24 />}
            buttons={
                <>
                    <Button mode="primary" style="accent" stretched onClick={handleDelete} loading={submitting}>
                        {trls[TrlKeys.buttons.submit]}
                    </Button>
                    <Button mode="secondary" style="accent" stretched onClick={handleCloseModal}>
                        {trls[TrlKeys.buttons.cancel]}
                    </Button>
                </>
            }
            visible={isVisible}
            onClose={handleCloseModal}
            title={isOneSelectedVacancy ? trls[TrlKeys.titleOne] : trls[TrlKeys.titleMany]}
            description={isOneSelectedVacancy ? trls[TrlKeys.subtitleOne] : trls[TrlKeys.subtitleMany]}
            layout="vertical"
        />
    );
};

export default translation(DeleteVacanciesFromFunnelModal);
