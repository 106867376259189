import { Text, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import VacancyItem from 'src/components/EmployerVacancies/VacancyItem';
import translation from 'src/components/translation';
import { useSelectorNonNullable } from 'src/hooks/useSelector';
import { PublicationCodeAvailableForPurchase, ServiceItemCode } from 'src/models/price/productCodes';
import { getPublicationCode, getPublicationsCount } from 'src/utils/price/products';

const TrlKeys = {
    title: 'employer.myVacancies.prolongate.availableToPurchase.title',
};

const AvailableToPurchase: TranslatedComponent = ({ trls }) => {
    const purchase = useSelectorNonNullable(({ prolongateInfo }) => prolongateInfo.availableToPurchase.purchase);
    const spendLength = useSelectorNonNullable(
        ({ prolongateInfo }) => prolongateInfo.availableToSpend.vacancyIds.length
    );
    const freeLength = useSelectorNonNullable(
        ({ prolongateInfo }) => prolongateInfo.availableToFreeProlongate.vacancyIds.length
    );

    if (!purchase) {
        return null;
    }

    return (
        <>
            {(!!spendLength || !!freeLength) && (
                <>
                    {!!spendLength && <VSpacing default={16} />}
                    <Text typography="subtitle-1-semibold">{trls[TrlKeys.title]}</Text>
                    <VSpacing default={8} />
                </>
            )}
            {purchase.products.map((product) => {
                const billingCode = getPublicationCode(product) as PublicationCodeAvailableForPurchase;
                const hasZpCrossposting = Object.values(product.products || []).some(
                    (sub) => sub.code === ServiceItemCode.ZpCrosspost
                );

                return (
                    <VacancyItem
                        key={billingCode + product.regionTrl + product.profRoleGroupsTrls[0]}
                        billingCode={billingCode}
                        currency={product.currency}
                        region={product.regionTrl}
                        profRoleGroup={product.profRoleGroupsTrls[0]}
                        price={product.price}
                        amount={getPublicationsCount(product) ?? 0}
                        isZpCrossposting={hasZpCrossposting}
                    />
                );
            })}
        </>
    );
};

export default translation(AvailableToPurchase);
