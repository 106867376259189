import { useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import Analytics from '@hh.ru/analytics-js';
import { usePush } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind } from 'bloko/blocks/button';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import Modal, { ModalHeader, ModalTitle, ModalFooter } from 'bloko/blocks/modal';
import VSpacing from 'bloko/blocks/vSpacing';

import { useNotification } from 'src/components/Notifications/Provider';
import vacancyAutoProlongation from 'src/components/VacancyActions/vacancyAutoProlongation';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import AutoProlongationPurchase from 'src/components/VacancyModals/AutoProlongation/AutoProlongationPurchase';

const AutoProlongation = ({ handleCloseModal, vacanciesIds, isVisible, requirements, afterSuccessAction, trls }) => {
    const dispatch = useDispatch();
    const [showAgreementsError, setShowAgreementsError] = useState(false);
    const [selectedAgreement, setSelectedAgreement] = useState(null);
    const [planLoading, setPlanLoading] = useState(false);
    const [purchaseLoading, setPurchaseLoading] = useState(false);
    const location = useSelector((state) => state.router.location);
    const modalRef = useRef(null);
    const { addNotification } = useNotification();
    const push = usePush();

    const { purchaseRequired, purchaseInfo } = requirements;

    useEffect(() => {
        modalRef.current &&
            Analytics.sendHHEventElementShown(modalRef.current, {
                name: purchaseRequired ? 'auto_prolongation_purchase_suggest' : 'auto_prolongation_confirmation',
                vacancy_id: vacanciesIds,
            });
    }, [modalRef, purchaseRequired, vacanciesIds]);

    const handleSubmitCommon = useCallback(
        async (eventName, withPurchasing) => {
            try {
                await dispatch(
                    vacancyAutoProlongation(
                        {
                            action: 'enable',
                            vacanciesIds,
                            withPurchasing,
                            agreementId: selectedAgreement,
                            location,
                        },
                        addNotification,
                        push
                    )
                );

                Analytics.sendHHEventButtonClick(eventName);

                if (afterSuccessAction) {
                    afterSuccessAction();

                    return;
                }

                handleCloseModal();
            } catch {
                handleCloseModal();
            }
        },
        [
            addNotification,
            afterSuccessAction,
            dispatch,
            handleCloseModal,
            location,
            push,
            selectedAgreement,
            vacanciesIds,
        ]
    );

    const handleSubmitPlan = useCallback(() => {
        if (planLoading || purchaseLoading) {
            return;
        }

        setPlanLoading(true);
        handleSubmitCommon('auto_prolongation_publication_plan', false);
    }, [handleSubmitCommon, planLoading, purchaseLoading]);

    const handleSubmitPurchase = useCallback(() => {
        Analytics.sendHHEventButtonClick('modal_auto_prolongation_button');

        if (planLoading || purchaseLoading) {
            return;
        }

        if (purchaseRequired && !selectedAgreement) {
            setShowAgreementsError(true);
            return;
        }

        setPurchaseLoading(true);
        const eventName = purchaseRequired ? 'auto_prolongation_publication_purchase' : 'auto_prolongation_confirm';
        handleSubmitCommon(eventName, purchaseRequired);
    }, [handleSubmitCommon, planLoading, purchaseLoading, purchaseRequired, selectedAgreement]);

    const handleAgreementSelect = useCallback((agreementId) => {
        setShowAgreementsError(!agreementId);
        setSelectedAgreement(agreementId);
    }, []);

    const handleCancel = useCallback(() => {
        const eventName = purchaseRequired ? 'auto_prolongation_publication_cancel' : 'auto_prolongation_cancel';
        Analytics.sendHHEventButtonClick(eventName);
        handleCloseModal();
    }, [handleCloseModal, purchaseRequired]);

    return (
        <Modal visible={isVisible} onClose={handleCloseModal}>
            <ModalHeader outlined>
                <ModalTitle>{trls[AutoProlongation.trls.modalTitle]}</ModalTitle>
            </ModalHeader>
            <div
                className="vacancy-auto-prolongation-modal-content"
                data-qa="vacancy-auto-prolongation-info"
                ref={modalRef}
            >
                <div data-qa="vacancy-auto-prolongation-info">{trls[AutoProlongation.trls.info]}</div>
                {purchaseRequired && (
                    <>
                        <VSpacing base={10} />
                        <AutoProlongationPurchase
                            showError={showAgreementsError}
                            onSelect={handleAgreementSelect}
                            {...purchaseInfo}
                        />
                    </>
                )}
            </div>
            <ModalFooter>
                <div className="vacancy-auto-prolongation-modal-button-grid">
                    <div className="vacancy-auto-prolongation-modal-button-grid__item">
                        <Button stretched onClick={handleCancel}>
                            {trls[AutoProlongation.trls.cancel]}
                        </Button>
                    </div>
                    {purchaseRequired && (
                        <div className="vacancy-auto-prolongation-modal-button-grid__item">
                            <Button
                                stretched
                                loading={planLoading && <Loading scale={LoadingScale.Small} />}
                                onClick={handleSubmitPlan}
                                data-qa="vacancies-autoProlongationPopup-enable-without-purchasing"
                            >
                                {trls[AutoProlongation.trls.enableWithoutPurchasing]}
                            </Button>
                        </div>
                    )}
                    <div className="vacancy-auto-prolongation-modal-button-grid__item">
                        <Button
                            stretched
                            loading={purchaseLoading && <Loading scale={LoadingScale.Small} />}
                            onClick={handleSubmitPurchase}
                            kind={ButtonKind.Primary}
                            data-qa="vacancies-autoProlongationPopup-enable"
                        >
                            {purchaseRequired
                                ? trls[AutoProlongation.trls.buyAndEnable]
                                : trls[AutoProlongation.trls.enable]}
                        </Button>
                    </div>
                </div>
            </ModalFooter>
        </Modal>
    );
};

AutoProlongation.trls = {
    enable: 'employer.vacancies.autoProlongation.plan',
    buyAndEnable: 'employer.vacancies.autoProlongation.buyAndEnable',
    enableWithoutPurchasing: 'employer.vacancies.autoProlongation.enableWithoutPurchasing',
    cancel: 'employer.myVacancyes.move.cancel',
    modalTitle: 'employer.vacancies.autoProlongation.modal.title.enable',
    info: 'employer.vacancies.autoProlongation.modal.text.enable',
};

export default translation(AutoProlongation);
