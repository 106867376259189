import { Card, Skeleton, VSpacing } from '@hh.ru/magritte-ui';

import styles from './skeleton-advice-card.less';

const SkeletonAdviceCard = () => (
    <>
        <Card padding={12} borderRadius={24} borderWidth="default" stretched>
            <div className={styles.skeletonAdviceCardContent}>
                {/* Заголовок */}
                <Skeleton width={260} height={20} borderRadius={8} loading />
                <VSpacing default={24} />
                {/* Текст */}
                <Skeleton height={16} borderRadius={8} loading />
                <VSpacing default={8} />
                <Skeleton width={200} height={16} borderRadius={8} loading />
            </div>
            {/* Кнопка */}
            <Skeleton height={40} borderRadius={12} loading />
        </Card>
        <VSpacing default={16} />
    </>
);

export default SkeletonAdviceCard;
