import { useCallback, useMemo, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';

import {
    Action,
    ActionBar,
    BottomSheet,
    BottomSheetFooter,
    Card,
    Modal,
    NavigationBar,
    Segment,
    Segmented,
    VSpacing,
    Text,
    Button,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import CreateFunnelForm, {
    CreateFunnelValues,
    FORM_NAME,
    NEW_FUNNEL_KEY,
} from 'src/components/Employer/Funnel/components/CreateFunnelForm';
import StageList from 'src/components/VacancyFunnel/StageList';
import { StageType } from 'src/components/VacancyFunnel/types';
import translation from 'src/components/translation';

import useCreateFunnel from 'src/components/Employer/Funnel/components/CreateFunnelModal/hooks/useCreateFunnel';
import useModalProps from 'src/components/Employer/Funnel/components/CreateFunnelModal/hooks/useModalProps';
import useShowFunnel from 'src/components/Employer/Funnel/components/CreateFunnelModal/hooks/useShowFunnel';

import styles from './styles.less';

const TrlKeys = {
    stage: {
        inProgress: 'employer.vacancyresponses.funnel.states.inProgress',
        discard: 'employer.vacancyresponses.funnel.states.discard',
    },
    funnelTitle: 'vacancy.createFunnel.modal.showFunnel.title',
    buttons: {
        create: 'vacancy.createFunnel.modal.buttons.create',
    },
};

interface CreateFunnelModalProps {
    isVisible: boolean;
    handleCloseModal: () => void;
    selectedVacancyIds: number[];
}

const CreateFunnelModal: TranslatedComponent<CreateFunnelModalProps> = ({
    isVisible,
    handleCloseModal,
    selectedVacancyIds,
    trls,
}) => {
    const vacancyFunnel = useSelectorNonNullable((state) => state.vacancyFunnel);
    const managerVacancies = useSelectorNonNullable(({ managerVacancies }) => managerVacancies);
    const vacancies = managerVacancies.vacanciesGroups?.vacanciesGroupList.flatMap(
        (vacanciesGroup) => vacanciesGroup.vacanciesList
    );

    const initialValues = useMemo<CreateFunnelValues>(
        () => ({
            funnelName: '',
        }),
        []
    );

    const [selectedVacancyId, setSelectedVacancyId] = useState('');

    const selectedFunnelId = useMemo<string>(() => {
        if (!vacancies || !selectedVacancyId || selectedVacancyId === NEW_FUNNEL_KEY) {
            return '';
        }

        const vacancy = vacancies.find(({ vacancyId }) => vacancyId.toString() === selectedVacancyId);

        return vacancy?.funnel?.funnelId.toString() || '';
    }, [selectedVacancyId, vacancies]);

    const selectedVacancies = useMemo(() => {
        if (!vacancies) {
            return [];
        }

        return vacancies.filter((vacancy) => selectedVacancyIds.includes(vacancy.vacancyId));
    }, [vacancies, selectedVacancyIds]);

    const vacanciesInDifferentFunnels = useMemo(() => {
        const funnelIds = selectedVacancies.map((vacancy) => vacancy.funnel?.funnelId);

        return new Set(funnelIds).size > 1;
    }, [selectedVacancies]);

    const handleVacancyChange = useCallback((funnelId: string) => {
        setSelectedVacancyId(funnelId);
    }, []);

    const { onSubmit } = useCreateFunnel({
        selectedVacancyIds,
        trls,
        selectedFunnelId,
        handleCloseModal,
    });

    const { funnelModalOpen, funnelLoading, funnelStage, setFunnelStage, handleShowFunnel, closeFunnelModal } =
        useShowFunnel({ selectedFunnelId });

    const { title, titleDescription, secondaryButton } = useModalProps({
        selectedVacancyIds,
        trls,
        selectedVacancyId,
        funnelLoading,
        vacanciesInDifferentFunnels,
        handleShowFunnel,
        handleCloseModal,
    });

    const funnel = (
        <>
            <Segmented
                mode="label"
                onChange={(event) => setFunnelStage(event.target.value as StageType)}
                selectedValue={funnelStage}
                size="small"
                stretched
            >
                <Segment value="inProgress">{trls[TrlKeys.stage.inProgress]}</Segment>
                <Segment value="discard">{trls[TrlKeys.stage.discard]}</Segment>
            </Segmented>

            <VSpacing default={16} />

            <Card borderRadius={12} stretched borderWidth="default">
                <div className={styles.stageListWrapper}>
                    <StageList activeSection={funnelStage} readOnly />
                </div>
            </Card>
        </>
    );

    return (
        <FinalForm
            onSubmit={onSubmit}
            initialValues={initialValues}
            render={({ handleSubmit, submitting }) => {
                const funnelForm = (
                    <CreateFunnelForm
                        showVacancySelector={vacanciesInDifferentFunnels}
                        selectedVacancies={selectedVacancies}
                        handleVacancyChange={handleVacancyChange}
                        handleSubmit={handleSubmit}
                    />
                );

                const rightButton = (
                    <Button mode="primary" style="accent" type="submit" form={FORM_NAME} loading={submitting}>
                        {trls[TrlKeys.buttons.create]}
                    </Button>
                );

                return (
                    <>
                        <Modal
                            onClose={handleCloseModal}
                            visible={!funnelModalOpen && isVisible}
                            actions={<Action mode="secondary" onClick={handleCloseModal} icon={CrossOutlinedSize24} />}
                            title={title}
                            titleDescription={titleDescription}
                            footer={<ActionBar primaryActions={rightButton} secondaryActions={secondaryButton} />}
                            titleSize="medium"
                            titleDescriptionStyle="secondary"
                            size="large"
                        >
                            {funnelForm}
                        </Modal>
                        <BottomSheet
                            visible={isVisible}
                            header={
                                <NavigationBar
                                    title={title}
                                    right={<Action icon={CrossOutlinedSize24} onClick={handleCloseModal} />}
                                />
                            }
                            footer={
                                <BottomSheetFooter>
                                    {secondaryButton}
                                    {rightButton}
                                </BottomSheetFooter>
                            }
                            onClose={handleCloseModal}
                        >
                            <Text typography="label-3-regular" style="secondary">
                                {titleDescription}
                            </Text>
                            <VSpacing default={12} />
                            {funnelForm}
                        </BottomSheet>

                        <Modal
                            onClose={closeFunnelModal}
                            visible={funnelModalOpen}
                            actions={<Action mode="secondary" onClick={closeFunnelModal} icon={CrossOutlinedSize24} />}
                            size="small"
                            title={trls[TrlKeys.funnelTitle]}
                            titleDescriptionStyle="secondary"
                            titleDescription={vacancyFunnel?.name}
                        >
                            {funnel}
                        </Modal>
                        <BottomSheet
                            visible={funnelModalOpen}
                            header={
                                <NavigationBar
                                    title={trls[TrlKeys.funnelTitle]}
                                    subtitle={vacancyFunnel?.name}
                                    right={<Action icon={CrossOutlinedSize24} onClick={closeFunnelModal} />}
                                />
                            }
                            onClose={closeFunnelModal}
                            footer={<BottomSheetFooter />}
                        >
                            {funnel}
                        </BottomSheet>
                    </>
                );
            }}
        />
    );
};

export default translation(CreateFunnelModal);
