import { StaticDataFetcherItem } from '@hh.ru/magritte-ui';

export const FUNNEL_MAX_NAME_LENGTH = 100;

export const ValidationError = {
    REQUIRED: 'REQUIRED',
    MAX_LENGTH: 'MAX_LENGTH',
} as const;

export const ValidationErrors = [ValidationError.MAX_LENGTH, ValidationError.REQUIRED] as const;

export const funnelNameValidator = (value: string): string | undefined => {
    if (!value) {
        return ValidationError.REQUIRED;
    }

    if (value.length > FUNNEL_MAX_NAME_LENGTH) {
        return ValidationError.MAX_LENGTH;
    }

    return undefined;
};

export const AddVacanciesToFunnelValidationError = {
    EMPTY_LIST: 'EMPTY_LIST',
};

export const vacanciesToFunnelValidator = (value: StaticDataFetcherItem[]): string | undefined => {
    if (!value || value.length === 0) {
        return AddVacanciesToFunnelValidationError.EMPTY_LIST;
    }

    return undefined;
};
