import { useMemo } from 'react';

import {
    Action,
    Modal,
    Text,
    useBreakpoint,
    BottomSheet,
    BottomSheetFooter,
    Button,
    NavigationBar,
    ActionBar,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import urlParser from 'bloko/common/urlParser';

import AutoPublicationBillInfo from 'src/components/EmployerVacancies/BillInfo';
import VacancyPreview from 'src/components/VacancyPreview';
import translation from 'src/components/translation';
import { BillInfo } from 'src/models/employerVacancies/vacancy.types';

const TrlKeys = {
    awaitingPayment: 'employer.vacancy.draft.awaitingPayment',
    scheduledPublication: 'employer.vacancy.draft.scheduledPublication.title',
    print: 'employer.myVacancies.dashboard.draft.print',
};

interface PlannedPublicationProps {
    isVisible: boolean;
    handleCloseModal: () => void;
    draftId: number;
    billInfo: BillInfo;
    plannedPublicationType: 'scheduledPublication' | 'autoPublication';
}

const PlannedPublication: TranslatedComponent<PlannedPublicationProps> = ({
    isVisible,
    handleCloseModal,
    draftId,
    billInfo,
    plannedPublicationType,
    trls,
}) => {
    const { isMobile } = useBreakpoint();

    const printButtonUrl = useMemo<string>(() => {
        const params = urlParser.stringify({
            draftId,
            print: 'true',
            showHidden: 'true',
        });

        return `/vacancy/view?${params}`;
    }, [draftId]);

    const printButton = (
        <Button
            mode="tertiary"
            style="accent"
            Element={SPALink}
            to={printButtonUrl}
            target="_blank"
            data-qa="vacancy-print-view"
        >
            {trls[TrlKeys.print]}
        </Button>
    );

    const title = (
        <Text style="secondary" typography="paragraph-3-regular">
            <>
                {plannedPublicationType === 'autoPublication'
                    ? trls[TrlKeys.awaitingPayment]
                    : trls[TrlKeys.scheduledPublication]}
            </>
            {billInfo && (
                <>
                    {' '}
                    <AutoPublicationBillInfo {...billInfo} useGenitive />
                </>
            )}
        </Text>
    );

    const preview = <VacancyPreview draftId={draftId} visible={isVisible} showHidden autoWidth />;

    return (
        <>
            <Modal
                visible={isVisible}
                title={title}
                titleSize="medium"
                size="large"
                actions={<Action mode="secondary" onClick={handleCloseModal} icon={CrossOutlinedSize24} />}
                footer={<ActionBar primaryActions={printButton} />}
                onClose={handleCloseModal}
            >
                {preview}
            </Modal>

            {isMobile && (
                <BottomSheet
                    visible={isVisible}
                    header={<NavigationBar title={title} />}
                    footer={<BottomSheetFooter>{printButton}</BottomSheetFooter>}
                    onClose={handleCloseModal}
                >
                    {preview}
                </BottomSheet>
            )}
        </>
    );
};

export default translation(PlannedPublication);
