import { useState } from 'react';

import {
    Action,
    BottomSheet,
    BottomSheetFooter,
    Button,
    Cell,
    CellText,
    Modal,
    NavigationBar,
    Radio,
    SearchInput,
    useBreakpoint,
    VSpacingContainer,
    ActionBar,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import FuzzySearch from 'bloko/common/fuzzySearch';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import fetcher from 'src/utils/fetcher';

import TransferErrorNotification from 'src/components/VacancyModals/TransferArchivedVacancies/Notifications';

const TrlKeys = {
    title: 'employer.myVacancyes.move.title',
    transfer: 'employer.myVacancyes.move.save',
    cancel: 'employer.myVacancyes.move.cancel',
};

const MOVE_VACANCIES_URL = '/employer/movevacancies';

declare global {
    interface FetcherPostApi {
        [MOVE_VACANCIES_URL]: {
            queryParams: void;
            body: {
                id: number[];
                targetManagerId: number;
            };
            response: void;
        };
    }
}

interface TransferArchivedVacanciesProps {
    isVisible: boolean;
    archivedVacancies: number[];
    handleCloseModal: VoidFunction;
    onSuccessAction: VoidFunction;
}

const TransferArchivedVacancies: TranslatedComponent<TransferArchivedVacanciesProps> = ({
    trls,
    isVisible,
    archivedVacancies,
    handleCloseModal,
    onSuccessAction,
}) => {
    const [searchValue, setSearchValue] = useState('');
    const [targetManagerId, setTargetManagerId] = useState<number>();
    const managerFilter = useSelector((state) => state.archivedVacanciesFilters.managerFilter);
    const managers = managerFilter.filter((manager) => Number.isInteger(parseInt(manager.value, 10)));
    const { isMobile } = useBreakpoint();
    const { addNotification } = useNotification();

    const handleSubmit = () => {
        if (targetManagerId) {
            fetcher
                .postFormData(MOVE_VACANCIES_URL, {
                    id: archivedVacancies,
                    targetManagerId,
                })
                .then(() => {
                    handleCloseModal();
                    onSuccessAction();
                })
                .catch(() => {
                    addNotification(TransferErrorNotification);
                });
        }
    };

    const visibleManagers = searchValue
        ? managers.filter((manager) => {
              if (searchValue) {
                  return FuzzySearch.match(searchValue, manager.label);
              }
              return manager;
          })
        : managers;

    const transferVacanciesModalContent = (
        <VSpacingContainer default={12}>
            {visibleManagers.map((item) => (
                <Cell
                    key={item.value}
                    left={
                        <Radio
                            value={item.value}
                            onChange={({ target: { value } }) => setTargetManagerId(Number(value))}
                            checked={Number(item.value) === targetManagerId}
                        />
                    }
                >
                    <CellText>{item.label}</CellText>
                </Cell>
            ))}
        </VSpacingContainer>
    );

    const transferVacanciesModalCancel = (
        <Button key="0" mode="secondary" style="accent" onClick={handleCloseModal}>
            {trls[TrlKeys.cancel]}
        </Button>
    );

    const transferVacanciesModalContinue = (
        <Button key="1" mode="primary" style="accent" disabled={!targetManagerId} onClick={handleSubmit}>
            {trls[TrlKeys.transfer]}
        </Button>
    );

    const transferVacanciesModalSearch = <SearchInput value={searchValue} onChange={setSearchValue} />;

    return (
        <>
            <Modal
                title={trls[TrlKeys.title]}
                visible={!isMobile && isVisible}
                onClose={handleCloseModal}
                options={transferVacanciesModalSearch}
                actions={<Action mode="secondary" onClick={handleCloseModal} icon={CrossOutlinedSize24} />}
                footer={<ActionBar primaryActions={[transferVacanciesModalCancel, transferVacanciesModalContinue]} />}
            >
                {transferVacanciesModalContent}
            </Modal>
            <BottomSheet
                visible={isMobile && isVisible}
                onClose={handleCloseModal}
                height="full-screen"
                header={
                    <NavigationBar
                        title={trls[TrlKeys.title]}
                        right={<Action icon={CrossOutlinedSize24} onClick={handleCloseModal} />}
                        options={transferVacanciesModalSearch}
                    />
                }
                footer={
                    <BottomSheetFooter>
                        {[transferVacanciesModalContinue, transferVacanciesModalCancel]}
                    </BottomSheetFooter>
                }
            >
                {transferVacanciesModalContent}
            </BottomSheet>
        </>
    );
};

export default translation(TransferArchivedVacancies);
