import { AxiosError } from 'axios';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { AppStore } from 'src/app/store';
import { plannedPublicationSudoCancelError } from 'src/components/Notifications/EmployerVacancies';
import type { AddNotification } from 'src/components/Notifications/Provider/types';
import { removeAutoPublicationDraft } from 'src/models/employerVacancies/managerVacancies';
import { fetchAndUpdateVacanciesCount } from 'src/models/employerVacancies/vacanciesCount';
import fetcher from 'src/utils/fetcher';

import autoPublicationDisabled from 'src/components/VacancyModals/AutoPublication/autoPublicationNotification';

const DISABLE_AUTOPUBLICATION_URL = '/shards/employer/vacancies/auto_publication/disable';

declare global {
    interface FetcherPostApi {
        [DISABLE_AUTOPUBLICATION_URL]: {
            queryParams: void;
            body: { draftId: number };
            response: void;
        };
    }
}

export default (
        draftId: number,
        addNotification: AddNotification
    ): ThunkAction<Promise<unknown>, AppStore, unknown, AnyAction> =>
    async (dispatch, getState) => {
        try {
            await fetcher.post(DISABLE_AUTOPUBLICATION_URL, { draftId });
        } catch (err) {
            const axiosError = err as AxiosError<{ error: string }>;
            if (axiosError.response?.data?.error === 'isSudo') {
                addNotification(plannedPublicationSudoCancelError);
                return;
            }

            defaultRequestErrorHandler(axiosError, addNotification);
            throw axiosError;
        }
        addNotification(autoPublicationDisabled);
        const managerId = parseInt(getState().employerManager?.id || '', 10);
        dispatch(removeAutoPublicationDraft({ draftId }));
        void fetchAndUpdateVacanciesCount(dispatch, managerId);
    };
