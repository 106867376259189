import Paths from 'src/app/routePaths';
import { VacancyAdvice, VacancySalaryAdvice } from 'src/models/vacancy/vacancyAdvices.types';

export enum Rating {
    Sad = '1',
    Halfsad = '2',
    Neutral = '3',
    Halfsmile = '4',
    Smile = '5',
}

export const SALARY_VACANCY_ADVICES: VacancySalaryAdvice[] = [
    VacancyAdvice.PublishSalary,
    VacancyAdvice.SalaryCloserToMarket,
    VacancyAdvice.SalaryAboveMarket,
    VacancyAdvice.SalaryMoreSpecific,
];

export const SUPPORTED_PAGES = [Paths.vacancyView, Paths.employerVacancies];

export const VACANCY_ADVICE_MODAL_SOURCE_EVENT = 'HH-PROXY-VACANCY_ADVICE_MODAL_SOURCE_EVENT';
export const VACANCY_ADVICE_MODAL_COMPONENT_EVENT = 'HH-PROXY-VACANCY_ADVICE_MODAL_COMPONENT_EVENT';

export enum VacancyAdviceModalEventType {
    Init = 'HH-PROXY-VACANCY_ADVICES_MODAL_INIT',
    SetData = 'HH-PROXY-VACANCY_ADVICES_MODAL_SET_DATA',
    SetAdvice = 'HH-PROXY-VACANCY_ADVICES_MODAL_SET_ADVICE',
    SetRating = 'HH-PROXY-VACANCY_ADVICES_MODAL_SET_RATING',
    OpenProlangateModal = 'HH-PROXY-VACANCY_ADVICES_MODAL_OPEN_PROLANGATE_MODAL',
    SendFeedback = 'HH-PROXY-VACANCY_ADVICES_MODAL_SEND_FEEDBACK',
}
