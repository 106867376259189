import { Dispatch, FC } from 'react';
import { AnyAction } from 'redux';

import { useSelector } from 'src/hooks/useSelector';
import { MIDDLE_DOT } from 'src/utils/constants/symbols';

import VacancyAdviceModalContentProxy from 'src/components/VacancyModals/VacancyAdviceModal/VacancyAdviceModalContentProxy';
import VacancyAdviceModalMagritte from 'src/components/VacancyModals/VacancyAdviceModal/VacancyAdviceModalMagritte';

interface VacancyAdviceModalProps {
    vacancyId: number;
    isVisible: boolean;
    handleCloseModal: () => void;
    dispatchModal: Dispatch<AnyAction>;
}

const VacancyAdviceModal: FC<VacancyAdviceModalProps> = ({ isVisible, handleCloseModal, dispatchModal }) => {
    const vacancyAdviceModalData = useSelector((state) => state.vacancyAdviceModalData);
    const { adviceWithStatistics } = vacancyAdviceModalData;
    const advices = adviceWithStatistics.map((item) => item.advice);
    const { vacancyId, vacancyName, vacancyAreaName } = vacancyAdviceModalData.vacancy;

    return (
        <VacancyAdviceModalMagritte
            numOfAdvices={advices.length}
            visible={Boolean(isVisible && advices.length)}
            onClose={handleCloseModal}
            vacancyId={vacancyId}
            titleDescription={`${vacancyName} ${MIDDLE_DOT} ${vacancyAreaName}`}
        >
            <VacancyAdviceModalContentProxy dispatchModal={dispatchModal} />
        </VacancyAdviceModalMagritte>
    );
};

export default VacancyAdviceModal;
