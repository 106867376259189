import { useMemo, useRef } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { useDispatch } from 'react-redux';

import {
    Action,
    ActionBar,
    BottomSheet,
    BottomSheetFooter,
    Button,
    Modal,
    NavigationBar,
    Text,
    VSpacing,
} from '@hh.ru/magritte-ui';
import { CheckCircleFilledSize24, CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { usePush } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { defaultMagritteRequestErrorHandler } from 'src/api/notifications/defaultRequestErrorHandler';
import AddVacanciesToFunnelForm, {
    FORM_NAME,
    AddVacanciesToFunnelValues,
} from 'src/components/Employer/Funnel/components/AddVacanciesToFunnelForm';
import { UPDATE_FUNNEL_VACANCIES_URL } from 'src/components/Employer/Funnel/fetchers';
import { updateFunnels } from 'src/components/Employer/Funnel/utils';
import Notification from 'src/components/Notifications/Notification';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import fetcher from 'src/utils/fetcher';

const TrlKeys = {
    title: 'vacancyFunnel.modals.addVacancies.title',
    subtitle: 'vacancyFunnel.modals.addVacancies.subtitle',
    submit: 'vacancyFunnel.modals.addVacancies.buttons.submit',
    cancel: 'vacancyFunnel.modals.addVacancies.buttons.cancel',
    successOne: 'vacancyFunnel.modals.addVacancies.feedback.success.one',
    succesMany: 'vacancyFunnel.modals.addVacancies.feedback.success.many',
};

interface AddVacanciesToFunnelProp {
    isVisible: boolean;
    handleCloseModal: () => void;
    funnelId: number;
}

const AddVacanciesToFunnel: TranslatedComponent<AddVacanciesToFunnelProp> = ({
    trls,
    funnelId,
    isVisible,
    handleCloseModal,
}) => {
    const abortControllerRef = useRef<AbortController | null>(null);
    const { addNotification } = useNotification();
    const dispatch = useDispatch();
    const push = usePush();

    const onSuccess = (values: AddVacanciesToFunnelValues) => {
        handleCloseModal();

        dispatch(updateFunnels(funnelId, values.vacancies, push));

        addNotification(Notification, {
            props: {
                children: values.vacancies.length === 1 ? trls[TrlKeys.successOne] : trls[TrlKeys.succesMany],
                addon: <CheckCircleFilledSize24 initialColor="positive" />,
            },
        });
    };

    const onError = (error: unknown) => {
        defaultMagritteRequestErrorHandler(error, addNotification);
    };

    const onSubmit = async (values: AddVacanciesToFunnelValues) => {
        try {
            abortControllerRef.current?.abort();
            const abortController = new AbortController();
            abortControllerRef.current = abortController;

            const vacancyIds = values.vacancies.map(({ value }) => Number(value));

            await fetcher.put(
                UPDATE_FUNNEL_VACANCIES_URL,
                { funnelId, vacancyIds },
                {
                    signal: abortControllerRef.current?.signal,
                }
            );

            onSuccess(values);
        } catch (error) {
            if (fetcher.isCancel(error)) {
                return;
            }

            onError(error);
        }
    };

    const cancelButton = (
        <Button mode="secondary" style="accent" onClick={handleCloseModal}>
            {trls[TrlKeys.cancel]}
        </Button>
    );

    const title = trls[TrlKeys.title];
    const subtitle = trls[TrlKeys.subtitle];

    const values = useMemo<AddVacanciesToFunnelValues>(() => ({ funnelId, vacancies: [] }), [funnelId]);

    return (
        <FinalForm
            onSubmit={onSubmit}
            initialValues={values}
            render={({ handleSubmit, submitting }) => {
                const form = <AddVacanciesToFunnelForm handleSubmit={handleSubmit} />;
                const actions = <Action mode="secondary" onClick={handleCloseModal} icon={CrossOutlinedSize24} />;

                const submitButton = (
                    <Button mode="primary" style="accent" type="submit" form={FORM_NAME} loading={submitting}>
                        {trls[TrlKeys.submit]}
                    </Button>
                );

                return (
                    <>
                        <Modal
                            onClose={handleCloseModal}
                            visible={isVisible}
                            actions={actions}
                            title={title}
                            titleDescription={subtitle}
                            footer={<ActionBar primaryActions={submitButton} secondaryActions={cancelButton} />}
                            titleSize="medium"
                            titleDescriptionStyle="secondary"
                            size="large"
                        >
                            {form}
                        </Modal>
                        <BottomSheet
                            visible={isVisible}
                            header={<NavigationBar title={title} right={actions} />}
                            footer={
                                <BottomSheetFooter>
                                    {cancelButton}
                                    {submitButton}
                                </BottomSheetFooter>
                            }
                            onClose={handleCloseModal}
                        >
                            <Text typography="label-3-regular" style="secondary">
                                {subtitle}
                            </Text>
                            <VSpacing default={12} />
                            {form}
                        </BottomSheet>
                    </>
                );
            }}
        />
    );
};

export default translation(AddVacanciesToFunnel);
