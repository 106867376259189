import { Dispatch, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';
import { AnyAction } from 'redux';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import paths from 'src/app/routePaths';
import ContainerForMicroFrontend from 'src/components/CodeInjector/ContainerForMicroFrontend';
import updateVacancyInfoAfterProlongate from 'src/components/EmployerVacancies/updateVacancyInfoAfterProlongate';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import useProlongateAction from 'src/hooks/useProlongateAction';
import { useSelector } from 'src/hooks/useSelector';
import { setVacancyAdvice } from 'src/models/employerVacancies/managerVacancies';
import { setAdviceVacancyAdviceModal } from 'src/models/vacancyAdviceModalData';

import SkeletonAdviceCard from 'src/components/VacancyModals/VacancyAdviceModal/SkeletonAdviceCard';
import {
    SUPPORTED_PAGES,
    VACANCY_ADVICE_MODAL_COMPONENT_EVENT,
    VACANCY_ADVICE_MODAL_SOURCE_EVENT,
    VacancyAdviceModalEventType,
} from 'src/components/VacancyModals/VacancyAdviceModal/constants';
import {
    SetAdviceDetail,
    OpenProlangateModalDetail,
    VacancyAdviceModalEventDetailMap,
} from 'src/components/VacancyModals/VacancyAdviceModal/types';

interface VacancyAdviceModalContentProxyProps {
    dispatchModal: Dispatch<AnyAction>;
}

const VacancyAdviceModalContentProxy: TranslatedComponent<VacancyAdviceModalContentProxyProps> = ({
    dispatchModal,
    trls,
}) => {
    const dispatch = useDispatch();
    const { addNotification } = useNotification();
    const { pathname } = useLocation();
    const vacancyAdviceModalData = useSelector((state) => state.vacancyAdviceModalData);
    const [loading, setLoading] = useState(true);

    const prolongateAction = useProlongateAction({
        dispatchModal,
        freeRenewalParameters: {
            lastActivationTimeIso: null,
            intervalMinutes: null,
        },
        trls,
    });

    const isEmployerVacanciesPage = Boolean(
        matchPath(pathname, {
            path: paths.employerVacancies,
            exact: true,
            strict: true,
        })
    );

    const setDataOnInitHandler = useCallback(() => {
        const setDataVacancyAdvicesModalEvent = new CustomEvent(VACANCY_ADVICE_MODAL_SOURCE_EVENT, {
            detail: {
                type: VacancyAdviceModalEventType.SetData,
                ...vacancyAdviceModalData,
            },
        });
        window.dispatchEvent(setDataVacancyAdvicesModalEvent);
        setLoading(false);
    }, [vacancyAdviceModalData]);

    const setVacancyAdviceHandler = useCallback(
        (detail: SetAdviceDetail) => {
            const { vacancyId, adviceWithStatistics } = detail;
            const isSupportedPage = SUPPORTED_PAGES.some((path) =>
                matchPath(pathname, {
                    path,
                    exact: true,
                    strict: true,
                })
            );

            if (!isSupportedPage) {
                return;
            }

            if (isEmployerVacanciesPage) {
                // указываем число советов в списке вакансий
                dispatch(setVacancyAdvice({ vacancyId, adviceWithStatistics }));
            }
            // указываем число советов в самой модалке
            dispatch(setAdviceVacancyAdviceModal(adviceWithStatistics));
        },
        [dispatch, pathname, isEmployerVacanciesPage]
    );

    const openProlangateModalHandler = useCallback(
        (detail: OpenProlangateModalDetail) => {
            const { vacancyId } = detail;

            void prolongateAction({
                vacanciesIds: [vacancyId],
                afterSuccessAction: ({ handleCloseModal, vacancyIds }) => {
                    dispatch(updateVacancyInfoAfterProlongate({ addNotification, vacancyIds }));
                    handleCloseModal();
                },
                additionalAnalyticsParams: {
                    fromPlace: 'vacancy_advice_modal',
                    vacanciesIds: String(vacancyId),
                },
            });
        },
        [addNotification, dispatch, prolongateAction]
    );

    useEffect(() => {
        const vacancyAdviceModalComponentEventHandler = <T extends keyof VacancyAdviceModalEventDetailMap>(
            e: CustomEventInit<VacancyAdviceModalEventDetailMap[T]>
        ) => {
            if (!e.detail?.type) {
                return;
            }
            switch (e.detail.type) {
                case VacancyAdviceModalEventType.Init:
                    requestIdleCallback(setDataOnInitHandler);
                    break;

                case VacancyAdviceModalEventType.SetAdvice:
                    setVacancyAdviceHandler(e.detail);
                    break;

                case VacancyAdviceModalEventType.OpenProlangateModal:
                    openProlangateModalHandler(e.detail);
                    break;

                default:
                    break;
            }
        };

        window.addEventListener(VACANCY_ADVICE_MODAL_COMPONENT_EVENT, vacancyAdviceModalComponentEventHandler);
        return () => {
            window.removeEventListener(VACANCY_ADVICE_MODAL_COMPONENT_EVENT, vacancyAdviceModalComponentEventHandler);
        };
    }, [openProlangateModalHandler, setDataOnInitHandler, setVacancyAdviceHandler]);

    const { adviceWithStatistics } = vacancyAdviceModalData ?? { adviceWithStatistics: [] };
    const advices = adviceWithStatistics.length >= 3 ? adviceWithStatistics.slice(0, 3) : adviceWithStatistics;

    return (
        <>
            {loading && advices.map((item) => <SkeletonAdviceCard key={item.advice} />)}
            <ContainerForMicroFrontend place={'vacancyAdviceModalContent'} preloadContent={null} />
        </>
    );
};

export default translation(VacancyAdviceModalContentProxy);
