import { ReactNode } from 'react';

import { LangTrls } from '@hh.ru/front-static-app';
import { Button } from '@hh.ru/magritte-ui';

import { NEW_FUNNEL_KEY } from 'src/components/Employer/Funnel/components/CreateFunnelForm';

type UseModalProps = (params: {
    selectedVacancyIds: number[];
    trls: LangTrls;
    selectedVacancyId: string;
    funnelLoading: boolean;
    vacanciesInDifferentFunnels: boolean;
    handleCloseModal: VoidFunction;
    handleShowFunnel: VoidFunction;
}) => {
    title: ReactNode;
    titleDescription: ReactNode;
    secondaryButton: ReactNode;
};

const TrlKeys = {
    title: {
        oneVacancy: 'vacancy.createFunnel.modal.title.one',
        manyVacancies: 'vacancy.createFunnel.modal.title.many',
    },
    description: {
        oneVacancy: 'vacancy.createFunnel.modal.description.oneVacancy',
        manyVacancies: 'vacancy.createFunnel.modal.description.manyVacancies',
        inDifferentFunnels: 'vacancy.createFunnel.modal.description.inDifferentFunnels',
    },
    buttons: {
        cancel: 'vacancy.createFunnel.modal.buttons.cancel',
        showFunnel: 'vacancy.createFunnel.modal.buttons.showFunnel',
    },
};

const useModalProps: UseModalProps = ({
    selectedVacancyIds,
    trls,
    selectedVacancyId,
    funnelLoading,
    vacanciesInDifferentFunnels,
    handleCloseModal,
    handleShowFunnel,
}) => {
    const getSecondaryButton = () => {
        if (selectedVacancyId && selectedVacancyId !== NEW_FUNNEL_KEY) {
            return (
                <Button mode="secondary" style="accent" onClick={handleShowFunnel} loading={funnelLoading}>
                    {trls[TrlKeys.buttons.showFunnel]}
                </Button>
            );
        }

        return (
            <Button mode="secondary" style="accent" onClick={handleCloseModal}>
                {trls[TrlKeys.buttons.cancel]}
            </Button>
        );
    };

    const getTitle = () => {
        return selectedVacancyIds.length === 1 ? trls[TrlKeys.title.oneVacancy] : trls[TrlKeys.title.manyVacancies];
    };

    const getSubtitle = () => {
        if (vacanciesInDifferentFunnels) {
            return trls[TrlKeys.description.inDifferentFunnels];
        }

        return selectedVacancyIds.length === 1
            ? trls[TrlKeys.description.oneVacancy]
            : trls[TrlKeys.description.manyVacancies];
    };

    return {
        title: getTitle(),
        titleDescription: getSubtitle(),
        secondaryButton: getSecondaryButton(),
    };
};

export default useModalProps;
