import { ReactNode } from 'react';
import { AxiosError } from 'axios';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import { Push } from '@hh.ru/redux-spa-middleware';
import Conversion from 'bloko/blocks/conversion';
import format from 'bloko/common/format';
import { LangTrls } from 'bloko/common/hooks/useTranslations';

import { defaultMagritteRequestErrorHandler } from 'src/api/notifications/defaultRequestErrorHandler';
import { AppStore } from 'src/app/store';
import fetchVacanciesByGroupId from 'src/components/Employer/Funnel/fetchers/fetchVacanciesByGroupId';
import { FUNNEL_MAX_NAME_LENGTH, ValidationError, ValidationErrors } from 'src/components/Employer/Funnel/validators';
import { getLeftToShow } from 'src/components/EmployerVacancies/utils';
import { AddNotification } from 'src/components/Notifications/Provider/types';
import {
    removeFunnels as removeFunnelsAction,
    removeVacanciesFromFunnels as removeVacanciesFromFunnelsAction,
    updateFunnelsAfterAddVacancies,
} from 'src/models/employerVacancies/managerVacancies';
import { Vacancy } from 'src/models/employerVacancies/vacancy.types';

const TrlKeys = {
    [ValidationError.REQUIRED]: 'vacancy.createFunnel.modal.errors.required',
    symbol: {
        one: 'vacancy.createFunnel.modal.errors.maxLength.one',
        many: 'vacancy.createFunnel.modal.errors.maxLength.many',
        some: 'vacancy.createFunnel.modal.errors.maxLength.some',
    },
};

/**
 * Делаем редирект со страницы объединённых вакансий,
 * если осталась только одна дефолтная воронка
 */
const isLeftOneDefaultFunnel = (store: AppStore): boolean => {
    const { vacanciesGroupList = [], totalVacanciesGroupCount = 0 } = store.managerVacancies.vacanciesGroups || {};

    const hasMoreFunnels = getLeftToShow(totalVacanciesGroupCount, vacanciesGroupList.length) > 0;

    return vacanciesGroupList.length === 1 && vacanciesGroupList[0].default && !hasMoreFunnels;
};

const refetchDefaultFunnel = (currentManagerId: string): ThunkAction<Promise<void>, AppStore, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        const state = getState();

        const defaultFunnel = state.managerVacancies.vacanciesGroups?.vacanciesGroupList.find(
            (funnel) => funnel.default && String(funnel.ownerEmployerManagerId) === currentManagerId
        );

        if (!defaultFunnel) {
            return;
        }

        const abortController = new AbortController();

        await dispatch(
            fetchVacanciesByGroupId(
                {
                    params: { groupId: defaultFunnel.groupId, replaceExistedVacancies: true },
                    signal: abortController.signal,
                },
                () => {}
            )
        );
    };
};

export const removeFunnels = (
    funnelIds: number[],
    currentManagerId: string,
    push: Push
): ThunkAction<Promise<void>, AppStore, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(removeFunnelsAction({ funnelIds, currentManagerId }));

        if (isLeftOneDefaultFunnel(getState())) {
            push('/employer/vacancies');
            return;
        }

        await dispatch(refetchDefaultFunnel(currentManagerId));
    };
};

export const removeVacanciesFromFunnels = (
    vacancyIds: number[],
    currentManagerId: string,
    push: Push
): ThunkAction<Promise<void>, AppStore, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(removeVacanciesFromFunnelsAction({ vacancyIds, currentManagerId }));

        const state = getState();

        if (isLeftOneDefaultFunnel(state)) {
            push('/employer/vacancies');
            return;
        }

        await dispatch(refetchDefaultFunnel(currentManagerId));
    };
};

export const updateFunnels = (
    funnelId: number,
    vacancies: Vacancy[],
    push: Push
): ThunkAction<void, AppStore, unknown, AnyAction> => {
    return async (dispatch, getState) => {
        dispatch(updateFunnelsAfterAddVacancies({ funnelId, vacancies }));

        await dispatch(
            fetchVacanciesByGroupId(
                {
                    params: { groupId: funnelId.toString(), replaceExistedVacancies: true },
                    signal: new AbortController().signal,
                },
                () => {}
            )
        );

        if (isLeftOneDefaultFunnel(getState())) {
            push('/employer/vacancies');
        }
    };
};

export const handleResponseErrors = (_error: AxiosError, addNotification: AddNotification): Record<string, string> => {
    const error = _error as AxiosError<{ error?: Record<string, string> }>;

    if (error.response?.data?.error) {
        const errorKey = error.response.data.error?.key;

        if (ValidationErrors.includes(errorKey)) {
            return {
                funnelName: errorKey,
            };
        }
    }

    defaultMagritteRequestErrorHandler(error, addNotification);
    return {};
};

export const getFunnelErrorMessage = (errorKey: string, trls: LangTrls): ReactNode => {
    switch (errorKey) {
        case '':
            return errorKey;
        case ValidationError.REQUIRED:
            return trls[TrlKeys[ValidationError.REQUIRED]];
        case ValidationError.MAX_LENGTH:
            return (
                <Conversion
                    value={FUNNEL_MAX_NAME_LENGTH}
                    one={trls[TrlKeys.symbol.one]}
                    some={trls[TrlKeys.symbol.some]}
                    many={trls[TrlKeys.symbol.many]}
                    format={(trl) =>
                        format(trl, {
                            '{0}': FUNNEL_MAX_NAME_LENGTH,
                        })
                    }
                    hasValue={false}
                />
            );
        default:
            return errorKey;
    }
};
