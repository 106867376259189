import { useRef } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { Title, TooltipHover, HSpacing, VSpacing, useBreakpoint } from '@hh.ru/magritte-ui';
import { ExclamationCircleOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import Conversion from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import useOnOffState from 'src/hooks/useOnOffState';

import MobileTooltip from 'src/components/VacancyModals/VacancyAdviceModal/MobileTooltip';
import { noop } from 'src/components/VacancyModals/VacancyAdviceModal/utils';

const TrlKeys = {
    helpfulAdvice: {
        one: 'employer.myVacancies.advice.helpfulAdvice.one',
        some: 'employer.myVacancies.advice.helpfulAdvice.some',
        many: 'employer.myVacancies.advice.helpfulAdvice.many',
    },
    helpfulAdviceHint1: 'employer.myVacancies.advice.helpfulAdviceHint.1',
    helpfulAdviceHint2: 'employer.myVacancies.advice.helpfulAdviceHint.2',
    close: 'close',
    tooltipTitle: 'employer.myVacancies.advice.tooltip.title.aboutAdvices',
};

interface VacancyAdvicesModalTitleProps {
    numOfAdvices: number;
    vacancyId: number;
}

const VacancyAdvicesModalTitle: TranslatedComponent<VacancyAdvicesModalTitleProps> = ({
    numOfAdvices,
    vacancyId,
    trls,
}) => {
    const { isMobile } = useBreakpoint();
    const activatorRef = useRef<HTMLSpanElement>(null);
    const [visibleMobileTip, onShowMobileTip, onCloseMobileTip] = useOnOffState(false);

    const onAppearTip = () => {
        Analytics.sendHHEvent('tags_advice_modal_header_info_shown', { vacancyId });
    };

    const tooltipAdivices = (
        <>
            {trls[TrlKeys.helpfulAdviceHint1]}
            <VSpacing default={4} />
            {trls[TrlKeys.helpfulAdviceHint2]}
        </>
    );

    return (
        <>
            <Title size="medium" Element="h4">
                <Conversion
                    value={numOfAdvices}
                    one={trls[TrlKeys.helpfulAdvice.one]}
                    some={trls[TrlKeys.helpfulAdvice.some]}
                    many={trls[TrlKeys.helpfulAdvice.many]}
                    hasValue={numOfAdvices > 1}
                />
                <HSpacing default={8} />
                <span ref={activatorRef} onClick={isMobile ? onShowMobileTip : noop}>
                    <ExclamationCircleOutlinedSize16 initialColor="secondary" />
                </span>
            </Title>
            <TooltipHover placement="right-top" activatorRef={activatorRef} onAppear={onAppearTip}>
                {tooltipAdivices}
            </TooltipHover>
            <MobileTooltip
                title={trls[TrlKeys.tooltipTitle]}
                visible={visibleMobileTip}
                onAppear={onAppearTip}
                onClose={onCloseMobileTip}
                closeText={trls[TrlKeys.close]}
            >
                {tooltipAdivices}
            </MobileTooltip>
        </>
    );
};

export default translation(VacancyAdvicesModalTitle);
