import { useEffect, useMemo, useRef } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import { useDispatch } from 'react-redux';

import {
    Action,
    ActionBar,
    BottomSheet,
    BottomSheetFooter,
    Button,
    Input,
    Modal,
    NavigationBar,
    Text,
    VSpacing,
} from '@hh.ru/magritte-ui';
import { CheckCircleFilledSize24, CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import format from 'bloko/common/format';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { CRUD_FUNNEL_URL } from 'src/components/Employer/Funnel/fetchers';
import { handleResponseErrors, getFunnelErrorMessage } from 'src/components/Employer/Funnel/utils';
import { funnelNameValidator } from 'src/components/Employer/Funnel/validators';
import Form from 'src/components/Form';
import Notification from 'src/components/Notifications/Notification';
import { useNotification } from 'src/components/Notifications/Provider';
import { FunnelInfo } from 'src/components/VacancyCreate/sections/PublicationType/FunnelSelector/fetchFunnelsList';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { renameFunnel } from 'src/models/employerVacancies/managerVacancies';
import fetcher from 'src/utils/fetcher';

const TrlKeys = {
    title: 'vacancyFunnel.modals.renameFunnel.title',
    feedback: {
        success: 'vacancyFunnel.modals.renameFunnel.success',
    },
    inputs: {
        placeholder: 'vacancyFunnel.modals.renameFunnel.placeholder',
    },
    buttons: {
        cancel: 'vacancyFunnel.modals.renameFunnel.buttons.cancel',
        submit: 'vacancyFunnel.modals.renameFunnel.buttons.submit',
    },
};

const FORM_NAME = 'rename-funnel';

interface RenameFunnelValues {
    funnelName: string;
}

interface RenameFunnelModalProps {
    isVisible: boolean;
    handleCloseModal: () => void;
    funnelId: number;
}

const RenameFunnelModal: TranslatedComponent<RenameFunnelModalProps> = ({
    funnelId,
    isVisible,
    trls,
    handleCloseModal,
}) => {
    const { addNotification } = useNotification();
    const abortControllerRef = useRef<AbortController | null>(null);
    const dispatch = useDispatch();

    const funnels = useSelector((state) => state.managerVacancies.vacanciesGroups?.vacanciesGroupList);

    const showNotification = (funnel: FunnelInfo) => {
        addNotification(Notification, {
            props: {
                children: format(trls[TrlKeys.feedback.success], {
                    '{0}': funnel.name,
                }),
                addon: <CheckCircleFilledSize24 initialColor="positive" />,
            },
        });
    };

    const handleResult = (funnel: FunnelInfo) => {
        dispatch(renameFunnel({ funnelId, funnelName: funnel.name }));
        handleCloseModal();
        showNotification(funnel);
    };

    const handleError = (error: unknown): Record<string, string> => {
        if (fetcher.isCancel(error)) {
            return {};
        }

        if (!fetcher.isAxiosError(error)) {
            console.error(error);
            return {};
        }

        return handleResponseErrors(error, addNotification);
    };

    const onSubmit = async (values: RenameFunnelValues): Promise<Record<string, string>> => {
        try {
            abortControllerRef.current?.abort();
            const abortController = new AbortController();
            abortControllerRef.current = abortController;

            const response = await fetcher.put(
                CRUD_FUNNEL_URL,
                { funnelId, funnelName: values.funnelName },
                {
                    signal: abortControllerRef.current?.signal,
                }
            );

            handleResult(response.data);
        } catch (error) {
            return handleError(error);
        }

        return {};
    };

    useEffect(() => {
        return () => {
            abortControllerRef.current?.abort();
        };
    }, []);

    const secondaryButton = (
        <Button mode="secondary" style="accent" onClick={handleCloseModal}>
            {trls[TrlKeys.buttons.cancel]}
        </Button>
    );

    const title = trls[TrlKeys.title];

    const subtitle = useMemo(() => {
        return funnels?.find((funnel) => funnel.groupId === funnelId.toString())?.name || '';
    }, [funnelId, funnels]);

    return (
        <FinalForm
            onSubmit={onSubmit}
            initialValues={{ funnelName: '' }}
            render={({ handleSubmit, submitting }) => {
                const funnelForm = (
                    <Form id={FORM_NAME} name={FORM_NAME} onSubmit={handleSubmit}>
                        <Field
                            name="funnelName"
                            render={({ input, meta }) => {
                                const error = (meta.error || meta.submitError) as string;
                                return (
                                    <Input
                                        {...input}
                                        invalid={meta.touched && Boolean(error)}
                                        errorMessage={getFunnelErrorMessage(error, trls)}
                                        placeholder={trls[TrlKeys.inputs.placeholder]}
                                    />
                                );
                            }}
                            validate={funnelNameValidator}
                        />
                    </Form>
                );

                const submitButton = (
                    <Button mode="primary" style="accent" type="submit" form={FORM_NAME} loading={submitting}>
                        {trls[TrlKeys.buttons.submit]}
                    </Button>
                );

                return (
                    <>
                        <Modal
                            onClose={handleCloseModal}
                            visible={isVisible}
                            actions={<Action mode="secondary" onClick={handleCloseModal} icon={CrossOutlinedSize24} />}
                            title={title}
                            titleDescription={subtitle}
                            footer={<ActionBar primaryActions={submitButton} secondaryActions={secondaryButton} />}
                            titleSize="medium"
                            titleDescriptionStyle="secondary"
                            size="medium"
                        >
                            {funnelForm}
                        </Modal>
                        <BottomSheet
                            visible={isVisible}
                            header={
                                <NavigationBar
                                    title={title}
                                    right={<Action icon={CrossOutlinedSize24} onClick={handleCloseModal} />}
                                />
                            }
                            footer={
                                <BottomSheetFooter>
                                    {secondaryButton}
                                    {submitButton}
                                </BottomSheetFooter>
                            }
                            onClose={handleCloseModal}
                        >
                            <Text typography="label-3-regular" style="secondary">
                                {subtitle}
                            </Text>
                            <VSpacing default={12} />
                            {funnelForm}
                        </BottomSheet>
                    </>
                );
            }}
        />
    );
};

export default translation(RenameFunnelModal);
