import { StaticDataFetcherItem } from '@hh.ru/magritte-ui';
import createStaticDataProvider from 'bloko/blocks/suggest/createStaticDataProvider';
import { DataProvider } from 'bloko/blocks/suggest/types';
import { fromTree } from 'bloko/common/tree/treeCollectionHelper';

import {
    AvailableVacanciesFilters,
    ExtendedSimpleFilterItemType,
    VacanciesFilterItems,
    VacanciesFilterKeys,
    VacanciesFilters,
} from 'src/models/employerVacancies/vacancy.types';
import {
    AreaGroup,
    EnrichClusterGroup,
    ManagerCluster,
    ManagerClusterGroup,
    ManagerName,
} from 'src/models/managerCluster';

import { getFullName } from 'src/components/EmployerVacancies/employerManager';

const getAreaGroup = (areaCluster: AreaGroup[]) =>
    areaCluster.reduce<AreaGroup[]>((allAreas, { childrenAreas }) => [...allAreas, ...childrenAreas], []);

const isAreaGroup = (groupName: string | ManagerName, isArea: boolean): groupName is string => isArea;

const getComplexClusterData = (
    { isComposite, clusterGroups }: { isComposite?: boolean; clusterGroups: AreaGroup[] | ManagerClusterGroup[] },
    isArea = false
) => {
    const clusterData = isArea ? getAreaGroup(clusterGroups as AreaGroup[]) : clusterGroups;
    const preparedData = clusterData.map<{ id: string; text: string }>(({ value, name }) => ({
        id: value,
        text: isAreaGroup(name, isArea) ? name : getFullName(name),
    }));

    if (isComposite) {
        return {
            isComposite,
            data: {
                suggest: createStaticDataProvider(preparedData),
                popup: fromTree(preparedData),
                raw: preparedData,
            },
        };
    }

    return { isComposite, data: preparedData };
};

interface SimpleFilter {
    isComposite?: boolean;
    data: { id: string; text: string }[];
}

export interface ComplexFilter {
    isComposite?: boolean;
    data: {
        suggest: DataProvider;
        popup: ReturnType<typeof fromTree>;
        raw: { id: string; text: string }[];
    };
}

export interface FilterCluster {
    area: SimpleFilter | ComplexFilter;
    managers: SimpleFilter | ComplexFilter;
    definingProperties: { data: EnrichClusterGroup[] };
    payForPerformance: { data: EnrichClusterGroup[] };
    premoderation: { data: EnrichClusterGroup[] };
    vacancyPublicationFormat: { data: EnrichClusterGroup[] };
    vacancyVisibility: { data: EnrichClusterGroup[] };
    auctionState: { data: EnrichClusterGroup[] };
    clickmeMultiPostingState: { data: EnrichClusterGroup[] };
}

export const prepareManagerCluster = (managerCluster: ManagerCluster): FilterCluster => {
    const {
        areaCluster,
        employerManagerCluster,
        premoderationStatusCluster,
        vacancyDefiningPropertiesCluster,
        vacancyPayForPerformanceCluster,
        vacancyPublicationFormatCluster,
        vacancyVisibilityCluster,
        auctionStateCluster,
        multiPostingStateCluster,
    } = managerCluster;

    return {
        area: areaCluster && getComplexClusterData(areaCluster, true),
        managers: employerManagerCluster && getComplexClusterData(employerManagerCluster),
        definingProperties: vacancyDefiningPropertiesCluster && {
            data: vacancyDefiningPropertiesCluster.clusterGroups,
        },
        payForPerformance: vacancyPayForPerformanceCluster && { data: vacancyPayForPerformanceCluster.clusterGroups },
        premoderation: premoderationStatusCluster && { data: premoderationStatusCluster.clusterGroups },
        vacancyPublicationFormat: vacancyPublicationFormatCluster && {
            data: vacancyPublicationFormatCluster.clusterGroups,
        },
        vacancyVisibility: vacancyVisibilityCluster && { data: vacancyVisibilityCluster.clusterGroups },
        auctionState: auctionStateCluster && { data: auctionStateCluster.clusterGroups },
        clickmeMultiPostingState: multiPostingStateCluster && { data: multiPostingStateCluster.clusterGroups },
    };
};

const isSimpleFilter = (filter: SimpleFilter | ComplexFilter | undefined): filter is SimpleFilter =>
    !!filter && !filter.isComposite;

export const getAvailableFiltersByClusters = ({
    area,
    managers,
    definingProperties,
    payForPerformance,
    vacancyPublicationFormat,
    vacancyVisibility,
    premoderation,
    auctionState,
    clickmeMultiPostingState,
}: FilterCluster): AvailableVacanciesFilters => {
    const areaData = isSimpleFilter(area) ? area.data : area?.data.raw;
    const managersData = isSimpleFilter(managers) ? managers.data : managers?.data.raw;
    return {
        [VacanciesFilterKeys.AreaId]: areaData && new Set(areaData.map(({ id }) => id)),
        [VacanciesFilterKeys.EmployerManagerId]: managersData && new Set(managersData.map(({ id }) => id)),
        [VacanciesFilterKeys.VacancyDefiningProperty]:
            definingProperties && new Set(definingProperties.data.map(({ value }) => value)),
        [VacanciesFilterKeys.VacancyPfpProperty]:
            payForPerformance && new Set(payForPerformance.data.map(({ value }) => value)),
        [VacanciesFilterKeys.VacancyPublicationFormat]:
            vacancyPublicationFormat && new Set(vacancyPublicationFormat.data.map(({ value }) => value)),
        [VacanciesFilterKeys.VacancyVisibility]:
            vacancyVisibility && new Set(vacancyVisibility.data.map(({ value }) => value)),
        [VacanciesFilterKeys.PremoderateStatus]: premoderation && new Set(premoderation.data.map(({ value }) => value)),
        [VacanciesFilterKeys.AuctionState]: auctionState && new Set(auctionState.data.map(({ value }) => value)),
        [VacanciesFilterKeys.ClickmeMultiPostingState]:
            clickmeMultiPostingState && new Set(clickmeMultiPostingState.data.map(({ value }) => value)),
    };
};

const sortFilterOptions = (a: StaticDataFetcherItem, b: StaticDataFetcherItem) => {
    if (!a.text || !b.text) {
        return 0;
    }
    if (a.text > b.text) {
        return 1;
    }
    if (a.text < b.text) {
        return -1;
    }
    return 0;
};

export const sortFilterItems = (a: ExtendedSimpleFilterItemType, b: ExtendedSimpleFilterItemType) => {
    if (a.checked < b.checked) {
        return 1;
    }
    if (a.checked > b.checked) {
        return -1;
    }
    if (a.text > b.text) {
        return 1;
    }
    if (a.text < b.text) {
        return -1;
    }
    return 0;
};

export const getFiltersStaticItemsByClusters = ({
    area,
    managers,
    definingProperties,
    payForPerformance,
    vacancyPublicationFormat,
    vacancyVisibility,
    premoderation,
    auctionState,
    clickmeMultiPostingState,
}: FilterCluster): Partial<VacanciesFilterItems> => {
    const areaData = isSimpleFilter(area) ? area.data : area?.data.raw;
    const managersData = isSimpleFilter(managers) ? managers.data : managers?.data.raw;

    const convertAndSort = (
        filterKey: VacanciesFilterKeys,
        filterData?: Array<{
            value?: string;
            id?: string;
            text: string;
        }>
    ) =>
        filterData
            ? filterData
                  .map(({ value, id, text }) => ({
                      value: (id || value)!,
                      text,
                      data: filterKey,
                  }))
                  .sort(sortFilterOptions)
            : undefined;

    return Object.fromEntries(
        Object.entries({
            [VacanciesFilterKeys.AreaId]: convertAndSort(VacanciesFilterKeys.AreaId, areaData),
            [VacanciesFilterKeys.EmployerManagerId]: convertAndSort(
                VacanciesFilterKeys.EmployerManagerId,
                managersData
            ),
            [VacanciesFilterKeys.VacancyDefiningProperty]: convertAndSort(
                VacanciesFilterKeys.VacancyDefiningProperty,
                definingProperties?.data
            ),
            [VacanciesFilterKeys.VacancyPfpProperty]: convertAndSort(
                VacanciesFilterKeys.VacancyPfpProperty,
                payForPerformance?.data
            ),
            [VacanciesFilterKeys.AuctionState]: convertAndSort(VacanciesFilterKeys.AuctionState, auctionState?.data),
            [VacanciesFilterKeys.ClickmeMultiPostingState]: convertAndSort(
                VacanciesFilterKeys.ClickmeMultiPostingState,
                clickmeMultiPostingState?.data
            ),
            [VacanciesFilterKeys.VacancyVisibility]: convertAndSort(
                VacanciesFilterKeys.VacancyVisibility,
                vacancyVisibility?.data
            ),
            [VacanciesFilterKeys.VacancyPublicationFormat]: convertAndSort(
                VacanciesFilterKeys.VacancyPublicationFormat,
                vacancyPublicationFormat?.data
            ),
            [VacanciesFilterKeys.PremoderateStatus]: convertAndSort(
                VacanciesFilterKeys.PremoderateStatus,
                premoderation?.data
            ),
        }).filter(([, filter]) => Boolean(filter?.length))
    );
};

const isAvailableFiltersHasValue = (
    availableFilters: AvailableVacanciesFilters,
    key: keyof AvailableVacanciesFilters
): availableFilters is Record<keyof AvailableVacanciesFilters, Set<string>> => !!availableFilters[key];

export const intersectFiltersAndAvailableFilters = (
    filters: VacanciesFilters,
    availableFilters: AvailableVacanciesFilters
): VacanciesFilters => {
    const { vacancyName, ...checkableFilters } = filters;

    for (const filterName of Object.keys(checkableFilters)) {
        checkableFilters[filterName] = isAvailableFiltersHasValue(availableFilters, filterName)
            ? filters[filterName].filter((id) => availableFilters[filterName].has(id))
            : (checkableFilters[filterName] = []);
    }

    return { ...checkableFilters, vacancyName };
};
