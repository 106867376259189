import {
    DataSection,
    decorateWithQueryTransformer,
    decorateWithResultProducer,
    defaultFetcher,
    StaticDataFetcherItem,
} from '@hh.ru/magritte-ui';

import { VacanciesList } from 'src/models/employerVacancies/vacancy.types';
import { CancelCallback } from 'src/utils/suggest/buildDataProvider';

interface SearchVacanciesResponse {
    vacanciesData: VacanciesList;
}

const SEARCH_URL = '/shards/employer/vacancies_dashboard/vacancies';

const fetcher = (url: string, onCancel: CancelCallback) => defaultFetcher<SearchVacanciesResponse>(url, onCancel);

const createDecorateWithQueryTransformer = (groupId?: string) =>
    decorateWithQueryTransformer(
        fetcher,
        (searchValue) => `${SEARCH_URL}?vacancyName=${searchValue}&groupId=${groupId}&order=BY_EXPIRE_TIME_ASC`
    );

export const createVacanciesFetcher = (groupId?: string) =>
    decorateWithResultProducer(
        createDecorateWithQueryTransformer(groupId),
        (result): DataSection<StaticDataFetcherItem>[] => {
            if (!result?.vacanciesData?.list) {
                return [{ type: 'cells', items: [] }];
            }

            return [
                {
                    type: 'cells',
                    items: result.vacanciesData.list.map((vacancy) => ({
                        data: {
                            ...vacancy,
                            value: vacancy.vacancyId.toString(),
                            text: vacancy.name,
                        },
                        value: vacancy.name,
                    })),
                },
            ];
        }
    );
